import { FC, PropsWithChildren } from 'react'
import { BGBlurCover, NoiseOverlay } from './styles.css'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { propsWithClassNames } from '@genie-fintech/ui/functions'

const LayoutWithBackground: FC<
  PropsWithChildren<TagsNoRef<'span'> & { removeNoiseOverlay?: boolean }>
> = ({ children, removeNoiseOverlay, ...props }) => {
  return (
    <span {...propsWithClassNames(props, BGBlurCover)}>
      {!removeNoiseOverlay && <span className={NoiseOverlay} />}

      {children}
    </span>
  )
}

export default LayoutWithBackground
