import Modal from '$components/Modal'
import { useModal } from '$components/Modal/hooks/useModal'
import { warningIconBox } from '$styles/common.css'
import { AlertTriangle } from 'lucide-react'
import { description, flexColumn, footer, main } from './styles.css'
import { title } from '@genie-fintech/ui/style/typography'
import { Button } from '@genie-fintech/ui/components'
import { FC, useCallback, useEffect, useRef } from 'react'
import { useRouterContext } from '$contexts/RouteContext/hooks'

const LeavePageWarning: FC<{ showWhen: boolean }> = ({ showWhen }) => {
  const modalActions = useModal()

  const { open, openModal, closeModal } = modalActions

  const action = useRef<VoidFunction>()

  const confirmButtonRef = useRef<HTMLButtonElement>(null)

  const { registerBeforeCallback } = useRouterContext()

  useEffect(
    () => {
      const cleanUp = registerBeforeCallback(redirect => {
        action.current = redirect
        if (showWhen) {
          openModal()
        }
        return !showWhen
      })

      return cleanUp
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showWhen, openModal]
  )

  // Focus the custom button when modal opens
  useEffect(() => {
    if (open && confirmButtonRef.current) {
      confirmButtonRef.current.focus()
    }
  }, [open])

  const onCancel = useCallback(() => {
    closeModal()
  }, [closeModal])

  const onConfirm = useCallback(() => {
    action.current?.()
    onCancel()
  }, [onCancel])

  return (
    <Modal {...modalActions} contentProps={{ style: { maxWidth: 480 } }}>
      <article className={flexColumn}>
        <main className={main}>
          <span className={warningIconBox}>
            <AlertTriangle size={20} />
          </span>

          <article className={flexColumn} style={{ gap: 16 }}>
            <p className={title.four}>
              You are about to continue without retrieving information or
              password!
            </p>
            <p className={description}>
              You haven't viewed or downloaded this user's password. If you
              proceed, you won't be able to retrieve it later and will need to
              create a new password for the user.
            </p>
          </article>
        </main>
        <footer className={footer}>
          <Button
            styleVariants={{ size: 'small', kind: 'neutral', type: 'outlined' }}
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            styleVariants={{ size: 'small' }}
            onClick={onConfirm}
            buttonRef={confirmButtonRef}
          >
            Continue
          </Button>
        </footer>
      </article>
    </Modal>
  )
}

export default LeavePageWarning
