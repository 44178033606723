import { Button } from '@genie-fintech/ui/components'
import { useCallback, useMemo, useState } from 'react'
import { APP_LAUNCHPAD_URL } from '$services/environments'
import { Copy, CopyCheck, Download, KeyRound } from 'lucide-react'
import { useRedirectProxy, useRouteSummary } from '$contexts/RouteContext/hooks'
import { TOOLTIP_ID } from '$app/constants'
import { cn } from '$app/utils'
import { defaultBackground, borderNeutral20 } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { ROUTE_NAMES } from '$router/config'
import { useBoolean, useToggle } from 'ahooks'
import { Icon } from '@genie-fintech/ui/icons'
import { TID } from '$services/api'
import LeavePageWarning from './LeavePageWarning'

const { colors } = themeVars

interface IProps {
  name: string
  email: string
  password: string
  userId: TID
  userType: 'service' | 'dashboard'
}

const Label = ({ label }: { label: string }) => (
  <label className="text-xs font-medium" style={{ color: colors.neutral[60] }}>
    {label}
  </label>
)

const Value = ({ value }: { value: string }) => (
  <article
    className="text-sm font-semibold"
    style={{ color: colors.text.light }}
  >
    {value}
  </article>
)

type TCopyData = {
  name: string
  email: string
  url: string
  password: string
}

const formatCopyValue = (data: TCopyData) => {
  const lineBreak = '\r\n'

  const copyData = [
    { label: 'Name', value: data.name },
    { label: 'Email', value: data.email },
    { label: 'Carro SSO Login Link', value: data.url },
    { label: 'Password', value: data.password }
  ]

  return copyData
    .map(v => v.label + lineBreak + v.value + lineBreak)
    .join(lineBreak)
}

const PreviewScreen = ({ name, email, password, userId, userType }: IProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const [isCopied, setIsCopied] = useState(false)

  const [hasViewPwd, { setTrue: setHasViewPwdTrue }] = useBoolean(false)

  const [hasCopiedPassword, { setTrue: setHasCopiedPasswordTrue }] =
    useBoolean(false)

  const [doneDownload, setDoneDownload] = useState(false)

  const [showPassword, { toggle: toggleShowPassword }] = useToggle()

  const { routeData } = useRouteSummary()

  const hasTakenAction =
    isCopied || doneDownload || hasViewPwd || hasCopiedPassword

  const proxyRedirect = useRedirectProxy()

  const isDashboardUser = userType === 'dashboard'

  const LOGIN_URL = useMemo(() => {
    if (isDashboardUser) {
      return window.location.origin + routeData.login.pathname
    }
    return APP_LAUNCHPAD_URL
  }, [isDashboardUser, routeData.login.pathname])

  const onClickPassword = useCallback(() => {
    setHasViewPwdTrue()
    toggleShowPassword()
  }, [setHasViewPwdTrue, toggleShowPassword])

  const onDownloadCSV = useCallback(() => {
    const csvHeaders = ['Email', 'Password', 'Carro SSO Login Link'].join(',')
    const csvRow = [email, `"${password}"`, LOGIN_URL].join(',')
    const csvContent = [csvHeaders, csvRow].join('\r\n')
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    const link = document.createElement('a')
    link.setAttribute('href', URL.createObjectURL(blob))
    link.setAttribute('download', `${name}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setDoneDownload(true)
  }, [name, email, password, LOGIN_URL])

  const onCopy = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()

      const copiedValue = formatCopyValue({
        name,
        email,
        password,
        url: LOGIN_URL
      })

      // Create a Blob for the HTML content
      const htmlBlob = new Blob([copiedValue], { type: 'text/html' })
      // Create a Blob for the plain text content
      const textBlob = new Blob([copiedValue], { type: 'text/plain' })

      // Create a ClipboardItem with the appropriate MIME types
      const clipboardItem = new ClipboardItem({
        'text/html': htmlBlob,
        'text/plain': textBlob
      })

      navigator.clipboard.writeText(copiedValue)

      navigator.clipboard.write([clipboardItem]).then(() => {
        setIsCopied(true)
      })
    },
    [name, email, password, LOGIN_URL]
  )

  const onGotoUserInfo = useCallback(() => {
    if (appId) {
      proxyRedirect(ROUTE_NAMES.APP_USER_DETAIL, {
        params: { appId, userId }
      })
      return
    }

    if (isDashboardUser) {
      proxyRedirect(ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL, {
        params: { dashboardUserId: userId }
      })
      return
    }

    proxyRedirect(ROUTE_NAMES.GLOBAL_SERVICE_USER_DETAIL, {
      params: { serviceUserId: userId }
    })
  }, [isDashboardUser, proxyRedirect, userId, appId])

  const onCopyPassword = useCallback(() => {
    navigator.clipboard.writeText(password).then(setHasCopiedPasswordTrue)
  }, [setHasCopiedPasswordTrue, password])

  return (
    <>
      <article className="flex flex-col gap-1.5">
        <article
          className={cn(
            'flex flex-col rounded-lg',
            defaultBackground,
            borderNeutral20
          )}
        >
          <header
            className="flex px-12 py-3 border-b"
            style={{ borderColor: colors.neutral[10] }}
          >
            <p
              className="text-xl font-semibold"
              style={{ color: colors.text.light }}
            >
              Retrieve Password and Information
            </p>
          </header>
          <main className="flex px-12 py-7">
            <article className="flex justify-between gap-1 flex-1">
              <article className="flex flex-col gap-6">
                <article className="flex flex-col gap-0.5">
                  <Label label="Name" />
                  <Value value={name} />
                </article>

                <article className="flex flex-col gap-0.5">
                  <Label label="Email Address" />
                  <Value value={email} />
                </article>

                <article className="flex flex-col gap-0.5">
                  <Label label="Carro SSO Login Link" />
                  <Value value={LOGIN_URL} />
                </article>

                <article className="flex flex-col gap-0.5">
                  <Label label="Password" />
                  {password ? (
                    <article className="flex items-center gap-2.5">
                      {showPassword && <Value value={password} />}

                      {!showPassword && (
                        <article className="flex items-center gap-1">
                          {new Array(password.length).fill(null).map((_, k) => (
                            <article
                              key={k}
                              className="inline-flex w-2 h-2 rounded-full"
                              style={{ background: colors.text.light }}
                            />
                          ))}
                        </article>
                      )}

                      <button onClick={onClickPassword}>
                        {
                          <Icon
                            namespace={showPassword ? 'EyeOn' : 'EyeOff'}
                            color="text.light"
                            width={20}
                          />
                        }
                      </button>

                      <button
                        data-tooltip-id={TOOLTIP_ID}
                        data-tooltip-content={
                          hasCopiedPassword ? 'Copied' : 'Copy'
                        }
                        onClick={onCopyPassword}
                      >
                        {hasCopiedPassword ? (
                          <CopyCheck size={20} />
                        ) : (
                          <Copy size={20} />
                        )}
                      </button>
                    </article>
                  ) : (
                    <article
                      className="flex gap-1.5 items-center"
                      style={{ color: colors.text.light }}
                    >
                      <KeyRound size={16} />
                      <span className="text-sm font-medium">
                        The password is encrypted and can not be seen.
                      </span>
                    </article>
                  )}
                </article>
              </article>

              <article>
                <Button
                  styleVariants={{ type: 'text', size: 'small' }}
                  data-tooltip-id={TOOLTIP_ID}
                  data-tooltip-content={isCopied ? 'Copied' : 'Copy'}
                  onClick={onCopy}
                >
                  Copy to Clipboard
                  {isCopied ? <CopyCheck size={14} /> : <Copy size={14} />}
                </Button>
              </article>
            </article>
          </main>
        </article>

        <article className="flex justify-end px-4 py-3 gap-2">
          {!doneDownload && password && (
            <Button
              styleVariants={{ kind: 'neutral', type: 'outlined' }}
              onClick={onDownloadCSV}
            >
              Download .csv File
              <Download />
            </Button>
          )}

          <Button onClick={onGotoUserInfo}>Go to User Profile</Button>
        </article>
      </article>

      <LeavePageWarning showWhen={!hasTakenAction} />
    </>
  )
}

export default PreviewScreen
