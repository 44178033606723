import { Button } from '@genie-fintech/ui/components'
import { tableRow } from '@genie-fintech/ui/style/element'
import { Clock, RefreshCcw } from 'lucide-react'
import { cell, normalCell, timerContainer } from './styles.css'
import { useCountDown, useRequest } from 'ahooks'
import { title } from '@genie-fintech/ui/style/typography'
import { deleteFailedLogin, FAILED_LOGIN, TID } from '$services/api'
import { formatDateWithGMT } from '$app/utils'
import { FC, useCallback } from 'react'
import { spin } from '@genie-fintech/ui/style/atoms'

const formatTimer = (value: number) => `${value}`.padStart(2, '0')

type Props = {
  userId?: TID
  data: FAILED_LOGIN
  onRemoveFailedLogin: (id: FAILED_LOGIN['id']) => void
}

const Timer = ({
  targetDate,
  onEnd
}: {
  targetDate: Date
  onEnd: VoidFunction
}) => {
  const [, info] = useCountDown({ targetDate, onEnd })

  const { minutes, seconds } = info

  return (
    <article className={timerContainer}>
      <Clock size={18} />
      <span className={title.four}>
        {formatTimer(minutes)}:{formatTimer(seconds)}
      </span>
    </article>
  )
}

const FailedLoginRow: FC<Props> = ({ data, onRemoveFailedLogin, userId }) => {
  const deleteFailedLoginRequest = useRequest(deleteFailedLogin, {
    manual: true
  })

  const onStopFailLogin = useCallback(() => {
    if (!userId) return
    deleteFailedLoginRequest.runAsync(userId, data.id).then(() => {
      onRemoveFailedLogin(data.id)
    })
  }, [userId, data.id, deleteFailedLoginRequest, onRemoveFailedLogin])

  return (
    <tr className={tableRow}>
      <td className={cell}>
        <Timer
          targetDate={new Date(data.locked_until)}
          onEnd={() => onRemoveFailedLogin(data.id)}
        />
      </td>

      <td className={normalCell}>{formatDateWithGMT(data.created_at)}</td>

      <td className={normalCell}>{data.location}</td>

      <td className={normalCell}>{data.ip_address}</td>

      <td className={cell}>
        <Button
          styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
          style={{ whiteSpace: 'nowrap' }}
          onClick={onStopFailLogin}
        >
          Stop Period and Continue
          <RefreshCcw
            size={16}
            className={deleteFailedLoginRequest.loading ? spin : undefined}
          />
        </Button>
      </td>
    </tr>
  )
}

export default FailedLoginRow
