import { useEffect, useState } from 'react'
import { useModal } from './useModal'
import { GENERAL_DURATION } from '@genie-fintech/ui/constants/transitions'

const delay = GENERAL_DURATION / 3

type Props = Pick<ReturnType<typeof useModal>, 'open'>

export const useDelayMounted = ({ open }: Props) => {
  const [isContentVisible, setIsContentVisible] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setIsContentVisible(open), delay)
    return () => clearTimeout(timeout)
  }, [open])

  return isContentVisible
}
