import {
  useRedirectPrompt,
  useRouteSummary
} from '$contexts/RouteContext/hooks'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { BaseText as BaseTextHookField } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { Icon } from '@genie-fintech/ui/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMount } from 'ahooks'
import { useCallback, useRef, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { z } from 'zod'
import { useAppBasicSettingService } from '$hooks/services'
import { useAppDetailStore, useAppScopeStore } from '$hooks/stores'
import { TAppBasicSetting, TAppBasicSettingPayload } from '$services/api'
import DragAndDropFileUpload from '$components/DragAndDropFileUpload'
import Select from '$components/Select'
import { BasicSettingIcon } from '$assets/svg'
import { useSaveEventListener } from '$hooks/actions/useSaveEventListener'
import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import { ROUTE_NAMES } from '$router/config'
import { APP_BRANDS, APP_ENVIRONMENT_OPTIONS } from '$app/constants'
import FooterAction from '$components/FooterAction'
import Breadcrumb from '$components/Breadcrumb/v2'
import { cn } from '$app/utils'
import { defaultBackground, borderNeutral20 } from '$styles/common.css'
import Asterisk from '$components/Asterisk'
import PublicKeyBlock from './PublicKeyBlock'
import { container } from './styles.css'

const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  description: z.string().trim(),
  login_behavior: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  scope: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  environment: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  logo: z.object({
    key: z.string().trim(),
    url: z.string().trim()
  }),
  brand: z.string().trim().min(1, 'Required!')
})

type TFormValues = z.infer<typeof schema>

const LOGIN_BEHAVIOR = [
  { value: 'email', label: 'EMAIL' },
  { value: 'phone', label: 'PHONE' },
  { value: 'username', label: 'USERNAME' }
]

const AppBasicSetting = () => {
  const saveButtonRef = useRef<HTMLButtonElement>(null)

  const [showApiToken, setShowApiToken] = useState(false)

  const [showEncryptionSecret, setShowEncryptionSecret] = useState(false)

  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  const {
    updateAppBasicSettingAsync,
    updatingAppBasicSetting,
    fetchAppBasicSettingAsync,
    fetchingAppBasicSetting,
    appBasicSetting
  } = useAppBasicSettingService()

  const { route } = useRouteSummary()

  const { appId } = route.params

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema)
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    setValue
  } = methods

  useRedirectPrompt({ shouldPrompt: isDirty })

  const appScopes = useAppScopeStore(state => state.appScopes)

  const transformToFormValues = useCallback(
    (data: TAppBasicSetting): TFormValues => {
      return {
        name: data.name ?? '',
        description: data.description ?? '',
        login_behavior: LOGIN_BEHAVIOR.find(
          d => d.value === data.login_behavior
        ) ?? { label: '', value: '' },
        scope: appScopes.find(d => +d.value === data?.scope?.id) ?? {
          label: '',
          value: ''
        },
        environment: {
          label: data.environment.value.toLocaleUpperCase(),
          value: `${data.environment.key}`
        },
        logo: {
          key: data.logo?.key ?? '',
          url: data.logo?.url ?? ''
        },
        brand: data.brand ?? APP_BRANDS[0].name
      }
    },
    [appScopes]
  )

  useSaveEventListener(() => {
    saveButtonRef.current?.click()
  })

  useMount(() => {
    if (!appId) return
    setTimeout(() => {
      fetchAppBasicSettingAsync({ application_id: appId }).then(({ data }) => {
        reset(transformToFormValues(data))
      })
    }, 1000)
  })

  const logo = useWatch({ name: 'logo', control })

  const brand = useWatch({ name: 'brand', control })

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId) return

    const {
      name,
      description,
      login_behavior,
      scope,
      logo: { key },
      brand,
      environment
    } = formValues

    const payload: TAppBasicSettingPayload = {
      name,
      description,
      scope_id: +scope.value,
      login_behavior: login_behavior.value,
      logo: { key },
      brand,
      environment: +environment.value
    }

    return updateAppBasicSettingAsync(appId, payload).then(() => {
      fetchAppBasicSettingAsync({ application_id: appId }).then(({ data }) => {
        reset(transformToFormValues(data))
      })
    })
  })

  const { api_token = '', encryption_key = '' } =
    appBasicSetting?.api_tokens.at(0) ?? {}

  const isProcessing = updatingAppBasicSetting || fetchingAppBasicSetting

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[{ name: 'App Details' }]}
      />

      <article className={container}>
        <form
          className={cn(
            'flex-1 flex flex-col relative rounded-lg',
            defaultBackground,
            borderNeutral20
          )}
          onSubmit={onSubmit}
        >
          <header className="flex items-center justify-between h-[56px] px-3 border-b border-[--colors-neutral-10]">
            <article className="flex items-center gap-x-2 text-[--colors-text-light]">
              <BasicSettingIcon />
              <p className="text-xl font-semibold">Basic Setting</p>
            </article>
          </header>

          <main className="flex-1 grid p-4 lg:grid-cols-[50%_50%] gap-4 pb-20">
            <article className="flex max-w-[400px] flex-col gap-y-1 px-5 mx-auto">
              <p className="text-xl font-semibold">{appName}'s BASIC SETTING</p>
              <p className="text-xs text-[--colors-neutral-50]">
                Configure essential preferences and system options in the basic
                settings.
              </p>
            </article>

            <article className="max-w-[400px] flex flex-col gap-y-4">
              <BaseTextHookField
                control={control}
                name="name"
                label="Application Name"
                required
              />

              <Controller
                name="environment"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <article className="flex flex-col gap-y-1">
                      <label className="text-sm font-medium">
                        Environment
                        <Asterisk />
                      </label>
                      <Select
                        {...field}
                        options={APP_ENVIRONMENT_OPTIONS.map(v => ({
                          label: v.value.toLocaleUpperCase(),
                          value: `${v.key}`
                        }))}
                        error={!!error?.message}
                      />
                      {error?.message && (
                        <p className="text-xs text-[--colors-danger-default]">
                          {error.message}
                        </p>
                      )}
                    </article>
                  )
                }}
              />

              <BaseText
                label="Client ID"
                inputProps={{
                  readOnly: true,
                  value: appBasicSetting?.client_id ?? ''
                }}
                required
                disabled
                affix={{ pre: <Icon namespace="Link" /> }}
              />

              <Textarea
                control={control}
                name="description"
                label="Description"
              />

              <Controller
                name="scope"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <article className="flex flex-col gap-y-1">
                      <label className="text-sm font-medium">
                        App Scope
                        <span className="text-[--colors-danger-default]">
                          *
                        </span>
                      </label>
                      <Select
                        {...field}
                        options={appScopes}
                        error={!!error?.message}
                      />
                      {error?.message && (
                        <p className="text-xs text-[--colors-danger-default]">
                          {error.message}
                        </p>
                      )}
                    </article>
                  )
                }}
              />

              <BaseText
                label="API Token"
                inputProps={{
                  readOnly: true,
                  value: api_token,
                  type: showApiToken ? 'text' : 'password',
                  autoComplete: ''
                }}
                required
                disabled
                affix={{
                  post: (
                    <Icon
                      className="cursor-pointer"
                      namespace={showApiToken ? 'EyeOff' : 'EyeOn'}
                      onClick={() => setShowApiToken(prev => !prev)}
                    />
                  )
                }}
              />

              <BaseText
                label="Encryption Secret"
                inputProps={{
                  readOnly: true,
                  value: encryption_key,
                  type: showEncryptionSecret ? 'text' : 'password',
                  autoComplete: ''
                }}
                required
                disabled
                affix={{
                  post: (
                    <Icon
                      className="cursor-pointer"
                      namespace={showEncryptionSecret ? 'EyeOff' : 'EyeOn'}
                      onClick={() => setShowEncryptionSecret(prev => !prev)}
                    />
                  )
                }}
              />

              <Controller
                name="login_behavior"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <article className="flex flex-col gap-y-1">
                      <label className="text-sm font-medium">
                        Login Behavior
                        <Asterisk />
                      </label>
                      <Select
                        {...field}
                        options={LOGIN_BEHAVIOR}
                        error={!!error?.message}
                      />

                      {error?.message && (
                        <p className="text-xs text-[--colors-danger-default]">
                          {error.message}
                        </p>
                      )}
                    </article>
                  )
                }}
              />

              <Controller
                name="logo"
                control={control}
                render={() => {
                  return (
                    <article className="flex flex-col gap-y-2">
                      <DragAndDropFileUpload
                        onUploadLogo={key =>
                          setValue('logo.key', key, { shouldDirty: true })
                        }
                        onChangeBrand={brand =>
                          setValue('brand', brand, { shouldDirty: true })
                        }
                        logo={logo}
                        brand={brand}
                      />
                    </article>
                  )
                }}
              />
            </article>
          </main>

          <FooterAction isFullPage={false}>
            <SaveWithShortCutButton
              disabled={!isDirty || isProcessing}
              loading={updatingAppBasicSetting}
            />
          </FooterAction>
        </form>

        {appBasicSetting && <PublicKeyBlock />}
      </article>
    </>
  )
}

export default AppBasicSetting
