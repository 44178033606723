import { FC, PropsWithChildren } from 'react'
import { content, overlay } from './styles.css'
import {
  propsWithClassNames,
  trueOrUndefined
} from '@genie-fintech/ui/functions'
import { TagsNoRef } from '@genie-fintech/ui/types'
import { useModal } from './hooks/useModal'
import { usePreventBackgroundScrolling } from './hooks/usePreventBackgroundScrolling'
import { useFocusTrap } from './hooks/useFocusTrap'
import { useDelayMounted } from './hooks/useDelayMounted'

type Props = ReturnType<typeof useModal> & {
  overlayProps?: TagsNoRef<'section'>
  contentProps?: TagsNoRef<'article'>
}

const Modal: FC<PropsWithChildren<Props>> = ({
  children,
  overlayProps,
  contentProps,
  ...modalProps
}) => {
  const { containerRef, open, isFocusMode, onOverlayClick } = modalProps

  const isContentVisible = useDelayMounted({ open })

  usePreventBackgroundScrolling({ open })

  useFocusTrap({ open, containerRef })

  return (
    <section
      ref={containerRef}
      role="dialog"
      aria-modal="true"
      aria-hidden={trueOrUndefined(!open)}
      onClick={onOverlayClick}
      {...propsWithClassNames(overlayProps, overlay({ open, isFocusMode }))}
    >
      <article {...propsWithClassNames(contentProps, content)}>
        {isContentVisible && children}
      </article>
    </section>
  )
}

export default Modal
