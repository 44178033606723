import {
  getAppPublicKey,
  getDownloadPublicKeyURL,
  postGeneratePublicKey
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const usePublicKeyService = () => {
  const {
    run: fetchAppPublicKey,
    runAsync: fetchAppPublicKeyAsync,
    loading: fetchingAppPublicKey,
    data: appPublicKeyData
  } = useRequest(getAppPublicKey, { manual: true })

  const { public_key } = appPublicKeyData?.data ?? {}

  const {
    run: generatePublicKey,
    runAsync: generatePublicKeyAsync,
    loading: generatingPublicKey
  } = useRequest(postGeneratePublicKey, {
    manual: true,
    onSuccess: (_, [{ force }]) => {
      toast.success(
        `Public key ${force ? 'regenerated' : 'generated'} successfully`
      )
    }
  })

  const {
    runAsync: fetchDownloadPublicKeyURLAsync,
    loading: fetchingDownloadPublicKeyURL
  } = useRequest(getDownloadPublicKeyURL, {
    manual: true
  })

  return {
    generatePublicKey,
    generatePublicKeyAsync,
    generatingPublicKey,

    fetchDownloadPublicKeyURLAsync,
    fetchingDownloadPublicKeyURL,

    fetchAppPublicKey,
    fetchAppPublicKeyAsync,
    fetchingAppPublicKey,
    public_key
  }
}
