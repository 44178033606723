import { cn } from '$app/utils'
import Breadcrumb from '$components/Breadcrumb/v2'
import Header from '$layouts/LayoutWithSidebar/Header'
import { ROUTE_NAMES } from '$router/config'
import {
  defaultBackground,
  borderNeutral20,
  description
} from '$styles/common.css'
import { Button, Checkbox, Spinner, Tabs } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { body, footnote, title } from '@genie-fintech/ui/style/typography'
import { ArrowUpRight, GitFork, Plus } from 'lucide-react'
import {
  cardContainer,
  container,
  eventBadge,
  flexColumn,
  flexRow
} from './styles.css'
import * as Dialog from '@radix-ui/react-dialog'
import { useInViewport, useRequest, useToggle } from 'ahooks'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DEFAULT_VALUES, schema, TField } from './constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import { useWebhookEventStore } from '$hooks/stores/useWebhookEventStore'
import { CheckedState } from '@radix-ui/react-checkbox'
import {
  APP_WEBHOOK,
  deleteWebhook,
  getWebhooksByAppId,
  postSentTestWebhook,
  postWebhook,
  putWebhook
} from '$services/api'
import { useApiListingParams } from '$hooks/actions'
import Loading from '$components/Loading'
import EmptyData from '$components/EmptyData'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { toast } from 'sonner'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import DeleteWithConfirm from '$components/DeleteWithConfirm'

const { colors } = themeVars

const PER_PAGE = 10

const AppWebhooks = () => {
  const { route } = useRouteSummary()

  const { appId } = route.params

  const [appWebhooks, setAppWebhooks] = useState<APP_WEBHOOK[]>([])

  const [modalOpen, { toggle }] = useToggle()

  const [selectedWebhookId, setSelectedWebhookId] = useState<string>()

  const targetRef = useRef(null)

  const webhookEvents = useWebhookEventStore(state => state.webhookEvents)

  const {
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<TField>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_VALUES
  })

  const webhook_event_ids = watch('webhook_event_ids')

  const {
    data: appWebhooksData,
    loading: fetchingAppWebhooksByAppId,
    runAsync: fetchAppWebhooksByAppIdAsync
  } = useRequest(getWebhooksByAppId, {
    manual: true
  })

  const { loading: savingWebhook, runAsync: saveWebhookAsync } = useRequest(
    postWebhook,
    { manual: true }
  )

  const { loading: updatingWebhook, runAsync: updateWebhookAsync } = useRequest(
    putWebhook,
    { manual: true }
  )

  const { loading: removingWebhook, runAsync: removeWebhookAsync } = useRequest(
    deleteWebhook,
    { manual: true }
  )

  const { loading: sendingTest, runAsync: sendTestAsync } = useRequest(
    postSentTestWebhook,
    { manual: true }
  )

  const { meta } = { ...appWebhooksData }

  const initialLoading = !appWebhooks.length && fetchingAppWebhooksByAppId

  const {
    pagerProps: { currentPage, jump }
  } = useApiListingParams({ total: meta?.total, perPage: PER_PAGE })

  const hasExtra = (() => {
    const isLastPage = meta?.last_page && meta?.last_page === currentPage
    if (isLastPage) return false

    const allFetched =
      !!appWebhooks.length && appWebhooks.length !== meta?.total
    return allFetched
  })()

  const isEmpty = !appWebhooks.length && !fetchingAppWebhooksByAppId

  useInViewport(targetRef, {
    callback: entry => {
      if (fetchingAppWebhooksByAppId) return

      if (entry.isIntersecting) {
        jump(Math.min(currentPage + 1, meta?.last_page ?? 1))
      }
    }
  })

  const fetchAppWebhooksByPageAsync = useCallback(
    (page: number) => {
      if (!appId) return
      return fetchAppWebhooksByAppIdAsync({
        appId,
        page,
        per_page: PER_PAGE
      }).then(({ data }) =>
        setAppWebhooks(prev => (page === 1 ? data : [...prev, ...data]))
      )
    },
    [fetchAppWebhooksByAppIdAsync, appId]
  )

  useEffect(() => {
    fetchAppWebhooksByPageAsync(currentPage)
  }, [fetchAppWebhooksByPageAsync, currentPage])

  const onAddWebhook = useCallback(() => {
    toggle()
  }, [toggle])

  const onClickUrl = useCallback(
    (id: string) => {
      setSelectedWebhookId(id)
      toggle()
      setTimeout(() => {
        const { url = '', webhook_events = [] } =
          appWebhooks.find(d => d.id === id) ?? {}
        reset({ url, webhook_event_ids: webhook_events.map(v => v.id) })
      }, 0)
    },
    [reset, appWebhooks, toggle]
  )

  const onUpdateWebhook = useCallback((value: Partial<APP_WEBHOOK>) => {
    setAppWebhooks(prev => {
      const newList = [...prev]
      const idx = newList.findIndex(d => d.id === value.id)
      if (idx < 0) return prev
      const currentValue = { ...newList[idx] }
      newList[idx] = { ...currentValue, ...value }
      return newList
    })
  }, [])

  const onSwitchChange = useCallback(
    (id: string, value: string) => {
      if (!appId) return

      const is_enabled = value === 'on'

      onUpdateWebhook({ id, is_enabled })

      updateWebhookAsync(appId, { id, is_enabled }).catch(() => {
        onUpdateWebhook({ id, is_enabled: !is_enabled })
      })
    },
    [appId, onUpdateWebhook, updateWebhookAsync]
  )

  const onDeleteWebhook = useCallback(
    async (id: string) => {
      if (!appId) return
      setSelectedWebhookId(id)
      removeWebhookAsync(appId, id)
        .then(() => {
          toast.success('Webhook has been deleted!')
          setAppWebhooks(prev => prev.filter(d => d.id !== id))
        })
        .finally(() => {
          setSelectedWebhookId(undefined)
        })
    },
    [appId, removeWebhookAsync]
  )

  const onWebhookEventCheck = useCallback(
    (checked: CheckedState, id: string) => {
      if (checked) {
        setValue('webhook_event_ids', [...webhook_event_ids, id])
        return
      }

      setValue(
        'webhook_event_ids',
        webhook_event_ids.filter(d => d !== id)
      )
    },
    [setValue, webhook_event_ids]
  )

  const onCancel = useCallback(() => {
    reset(DEFAULT_VALUES)
    setSelectedWebhookId(undefined)
    toggle()
  }, [reset, toggle])

  const onSendTest = useCallback(
    (id: string) => {
      if (!appId) return
      setSelectedWebhookId(id)
      sendTestAsync(appId, id)
        .then(() => {
          toast.success('Test has been sent!')
        })
        .finally(() => {
          setSelectedWebhookId(undefined)
        })
    },
    [appId, sendTestAsync]
  )

  const onSubmit = useCallback(
    (values: TField) => {
      if (!appId) return

      if (selectedWebhookId) {
        const payload = { id: selectedWebhookId, ...values }
        updateWebhookAsync(appId, payload).then(() => {
          toast.success('Webhook has been updated!')
          onUpdateWebhook({
            ...payload,
            webhook_events: webhookEvents.filter(d =>
              payload.webhook_event_ids.includes(d.id)
            )
          })
          onCancel()
        })
        return
      }

      saveWebhookAsync(appId, { ...values, is_enabled: true }).then(
        async () => {
          toast.success('Webhook has been created!')
          await fetchAppWebhooksByPageAsync(1)
          onCancel()
        }
      )
    },
    [
      appId,
      selectedWebhookId,
      saveWebhookAsync,
      fetchAppWebhooksByPageAsync,
      updateWebhookAsync,
      webhookEvents,
      onUpdateWebhook,
      onCancel
    ]
  )

  const processing = savingWebhook || updatingWebhook

  const webhook_event_ids_error_message =
    errors.root?.webhook_event_ids?.message ?? errors.webhook_event_ids?.message

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[{ name: 'App Details' }]}
      />

      <Header>
        <article
          className={cn(
            'flex flex-1 justify-between items-center px-6 py-4 gap-3 border-0 border-b',
            defaultBackground,
            borderNeutral20
          )}
          style={{ background: colors.area.high }}
        >
          <GitFork size={20} />

          <article className="flex-1">
            <p className={title.two} style={{ color: colors.text.light }}>
              Webhooks
            </p>
          </article>

          <Button onClick={onAddWebhook} styleVariants={{ size: 'small' }}>
            <Plus size={20} />
            Add Webhook
          </Button>
        </article>
      </Header>

      <article className={container}>
        <header className={flexColumn} style={{ gap: 4 }}>
          <h2 className={title.two}>WEBHOOKS MANAGEMENT</h2>
          <p className={description} style={{ maxWidth: 600 }}>
            Webhooks offer instant notifications for a range of email events,
            such as bounces, opens, and clicks, facilitating automated responses
            and improving the efficiency of email workflows.
          </p>
        </header>

        <main
          className={flexColumn}
          style={{ gap: 16, position: 'relative', minHeight: 200 }}
        >
          {initialLoading && <Loading />}

          {isEmpty && (
            <article className="flex-1 grid place-items-center">
              <EmptyData type="webhook" onClick={onAddWebhook} />
            </article>
          )}

          {appWebhooks.map(v => (
            <article key={v.id} className={cardContainer}>
              <article className={flexRow} style={{ gap: 16 }}>
                <p
                  className={body.three}
                  style={{
                    flex: 1,
                    color: colors.text.light,
                    cursor: 'pointer'
                  }}
                  onClick={() => onClickUrl(v.id)}
                >
                  {v.url}
                </p>

                <Button
                  styleVariants={{
                    kind: 'neutral',
                    type: 'text',
                    size: 'small'
                  }}
                  onClick={() => onSendTest(v.id)}
                >
                  Send test
                  {selectedWebhookId === v.id && sendingTest ? (
                    <Spinner />
                  ) : (
                    <ArrowUpRight size={16} />
                  )}
                </Button>

                <Tabs.Root
                  value={v.is_enabled ? 'on' : 'off'}
                  onValueChange={value => {
                    onSwitchChange(v.id, value)
                  }}
                  format={{ type: 'segmented' }}
                >
                  <Tabs.List styleVariants={{ hAlign: 'right' }}>
                    <Tabs.Trigger value="off">OFF</Tabs.Trigger>
                    <Tabs.Trigger value="on">ON</Tabs.Trigger>
                  </Tabs.List>
                </Tabs.Root>
              </article>

              <article style={{ display: 'flex', alignItems: 'end', gap: 16 }}>
                <article className={flexColumn} style={{ flex: 1, gap: 8 }}>
                  <p
                    className={footnote.two}
                    style={{ color: colors.neutral[40] }}
                  >
                    EVENTS
                  </p>

                  <article className={flexRow} style={{ gap: 8 }}>
                    {v.webhook_events.map(vv => (
                      <span key={vv.id} className={eventBadge}>
                        {vv.name}
                      </span>
                    ))}
                  </article>
                </article>

                <DeleteWithConfirm
                  onConfirm={() => onDeleteWebhook(v.id)}
                  loading={selectedWebhookId === v.id && removingWebhook}
                />
              </article>
            </article>
          ))}

          {hasExtra && (
            <article className="flex justify-center py-20">
              <article ref={targetRef} className="flex justify-center gap-x-2">
                <Spinner />
              </article>
            </article>
          )}
        </main>
      </article>

      <Dialog.Root open={modalOpen}>
        <Dialog.Portal>
          <Dialog.Overlay className="DialogOverlay" />
          <Dialog.Content
            className="DialogContent flex flex-col max-w-[800px]"
            aria-describedby=""
          >
            <Dialog.Title className="hidden" />

            <form onSubmit={handleSubmit(onSubmit, err => console.error(err))}>
              <main className={flexColumn} style={{ padding: 32, gap: 24 }}>
                <header className={flexColumn} style={{ gap: 4 }}>
                  <h2 className={title.two}>
                    {selectedWebhookId
                      ? 'EDIT WEBHOOKS URL'
                      : 'ADD NEW WEBHOOKS URL'}
                  </h2>
                  <p className={description} style={{ maxWidth: 600 }}>
                    Webhooks offer instant notifications for a range of email
                    events, such as bounces, opens, and clicks, facilitating
                    automated responses and improving the efficiency of email
                    workflows.
                  </p>
                </header>

                <BaseText
                  label="Webhook URL"
                  name="url"
                  control={control}
                  required
                  inputProps={{ type: 'url' }}
                />

                <article className={flexColumn} style={{ gap: 12 }}>
                  <p
                    className={footnote.two}
                    style={{ color: colors.neutral[40], paddingBottom: 4 }}
                  >
                    EVENTS
                  </p>

                  {webhook_event_ids_error_message && (
                    <p
                      className={footnote.one}
                      style={{ color: colors.danger[markedDefaultKey] }}
                    >
                      {webhook_event_ids_error_message}
                    </p>
                  )}

                  {webhookEvents.map(v => (
                    <Checkbox
                      key={v.id}
                      label={v.name}
                      boxProps={{
                        checked: webhook_event_ids.includes(v.id),
                        onCheckedChange: checked =>
                          onWebhookEventCheck(checked, v.id)
                      }}
                    />
                  ))}
                </article>
              </main>

              <footer
                className={flexRow}
                style={{ justifyContent: 'end', gap: 8, padding: 16 }}
              >
                <Button
                  type="button"
                  styleVariants={{
                    kind: 'neutral',
                    type: 'outlined',
                    size: 'small'
                  }}
                  onClick={onCancel}
                  disabled={processing}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  styleVariants={{ size: 'small' }}
                  disabled={processing}
                >
                  {processing && <Spinner />}
                  {selectedWebhookId ? 'Save' : 'Add'}
                </Button>
              </footer>
            </form>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  )
}

export default AppWebhooks
