import { warningIconBox } from '$styles/common.css'
import { AlertTriangle, RefreshCcw } from 'lucide-react'
import { flexColumn, stopAllContainer, stopAllDescription } from './styles.css'
import { Button } from '@genie-fintech/ui/components'
import { FC } from 'react'
import { spin } from '@genie-fintech/ui/style/atoms'

type Props = {
  onStopAll: VoidFunction
  loading?: boolean
}
const StopAllBlock: FC<Props> = ({ onStopAll, loading }) => {
  return (
    <article className={stopAllContainer}>
      <article className={flexColumn} style={{ gap: 16, maxWidth: 600 }}>
        <span className={warningIconBox}>
          <AlertTriangle size={20} />
        </span>

        <p className={stopAllDescription}>
          For the security purpose, the system lock this user login for a
          certain period of time due to the activity that violates our login
          threshold!
        </p>
      </article>

      <Button
        styleVariants={{ size: 'small' }}
        onClick={onStopAll}
        disabled={loading}
      >
        Stop All Period and Continue
        <RefreshCcw size={16} className={loading ? spin : undefined} />
      </Button>
    </article>
  )
}

export default StopAllBlock
