import { cn } from '$app/utils'
import CountryBadge from '$components/CountryBadge'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { redirect, ROUTE_NAMES } from '$router/config'
import { TAppPermissionDetail, TAppRoleListResponse } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  defaultBackground,
  borderNeutral20
} from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { ArrowLeft, PenLine } from 'lucide-react'
import { FC, PropsWithChildren, useCallback } from 'react'

const { colors } = themeVars

const columns: {
  key:
    | keyof Pick<TAppRoleListResponse['data'][number], 'name' | 'group'>
    | 'country'
  value: string
}[] = [
  { key: 'name', value: 'ROLE NAME' },
  { key: 'country', value: 'COUNTRY' },
  { key: 'group', value: 'GROUP' }
]

const Label: FC<PropsWithChildren> = ({ children }) => {
  return (
    <label
      className="font-medium text-xs"
      style={{ color: colors.neutral[60] }}
    >
      {children}
    </label>
  )
}

const Value: FC<PropsWithChildren> = ({ children }) => {
  return (
    <p className="font-semibold text-sm" style={{ color: colors.text.light }}>
      {children}
    </p>
  )
}

interface IProps {
  permission: TAppPermissionDetail
}

const PermissionInfo = ({ permission }: IProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const onBack = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSIONS, { params })
  }, [params])

  const onEdit = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSION_EDIT, { params })
  }, [params])

  return (
    <>
      <article
        className={cn(
          'flex flex-col rounded-lg',
          defaultBackground,
          borderNeutral20
        )}
      >
        <header
          className="flex items-center gap-2 px-4 py-3 border-b"
          style={{ borderColor: colors.neutral[10] }}
        >
          <Button onClick={onBack} styleVariants={{ type: 'text' }}>
            <ArrowLeft size={20} />
          </Button>

          <p
            className="flex-1 text-xl font-semibold"
            style={{ color: colors.text.light }}
          >
            {permission.name}
          </p>

          <Button
            styleVariants={{ kind: 'neutral', type: 'outlined', size: 'small' }}
            onClick={onEdit}
          >
            <PenLine size={16} />
            Edit
          </Button>
        </header>

        <main className="flex flex-col px-12 py-7 gap-4">
          <article className="flex flex-col gap-1">
            <p className="font-semibold">PERMISSION INFO</p>
            <p className="text-xs" style={{ color: colors.neutral[50] }}>
              Review detailed permissions to manage user access and
              functionality within the system.
            </p>
          </article>

          <article className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <article className="flex flex-col gap-0.5">
              <Label>Permission Name</Label>
              <Value>{permission.name}</Value>
            </article>

            <article className="flex flex-col gap-0.5">
              <Label>Module Name</Label>
              <Value>{permission.module_name}</Value>
            </article>

            <article className="flex flex-col gap-0.5">
              <Label>Description</Label>
              <Value>{permission.description ?? '-'}</Value>
            </article>
          </article>
        </main>
      </article>

      <article
        className={cn(
          'flex flex-col rounded-lg px-12 py-7',
          defaultBackground,
          borderNeutral20
        )}
      >
        <article className="flex flex-col gap-6">
          <article className="flex flex-col gap-1">
            <p className="font-semibold">ASSIGNED ROLES FOR THIS PERMISSION</p>
            <p className="text-xs text-[--colors-neutral-50]">
              Identify the roles associated with this permission to understand
              access levels and responsibilities.
            </p>
          </article>

          <article className={customTableContainer}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    <th className={customTableCell} style={{ width: 0 }}>
                      NO
                    </th>
                    {columns.map((col, key) => (
                      <th key={key} className={customTableCell}>
                        {col.value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className={tableBody}>
                  {permission.roles.map((data, rowKey) => (
                    <tr key={rowKey} className={tableRow}>
                      <td className={customTableCell}>{rowKey + 1}</td>
                      {columns.map((col, colKey) => (
                        <td key={colKey} className={customTableCell}>
                          {(() => {
                            if (col.key === 'country') {
                              return <CountryBadge name={data.country.name} />
                            }

                            if (col.key === 'group') {
                              return data.group.name
                            }

                            return data[col.key] ?? 'N/A'
                          })()}
                        </td>
                      ))}
                    </tr>
                  ))}

                  {!permission.roles.length && (
                    <tr className={tableRow}>
                      <td
                        colSpan={columns.length + 1}
                        style={{ textAlign: 'center' }}
                        className={customTableCell}
                      >
                        NO DATA
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </main>
          </article>
        </article>
      </article>
    </>
  )
}

export default PermissionInfo
