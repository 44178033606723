import { useEffect } from 'react'
import { useModal } from './useModal'

type Props = Pick<ReturnType<typeof useModal>, 'open'>

export const usePreventBackgroundScrolling = ({ open }: Props) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto'

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [open])
}
