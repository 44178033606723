import Modal from '$components/Modal'
import { Button } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'
import { FC, useCallback, useMemo } from 'react'
import { postApp } from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'
import { redirect, ROUTE_NAMES } from '$router/config'
import CreateAppForm from './CreateAppForm'
import { TField } from './constants'
import GeneratePublicKeyPrompt from './GeneratePublicKeyPrompt'
import { usePublicKeyService } from '$hooks/services/usePublicKeyService'
import { useModal } from '$components/Modal/hooks/useModal'

enum STATE {
  CREATE_APP,
  GENERATE_PUBLIC_KEY
}

type Props = ReturnType<typeof useModal>

const CreateApplication: FC<Props> = modalActions => {
  const {
    run: saveApp,
    loading: savingApp,
    data
  } = useRequest(postApp, {
    manual: true,
    onSuccess: () => {
      toast.success(`You've created app successfully!`)
    }
  })

  const { openModal, closeModal } = modalActions

  const { generatePublicKeyAsync, generatingPublicKey } = usePublicKeyService()

  const { id: appId, client_id } = { ...data?.data }

  const state: STATE = useMemo(() => {
    if (client_id) return STATE.GENERATE_PUBLIC_KEY
    return STATE.CREATE_APP
  }, [client_id])

  const onCreate = useCallback(
    ({ key, environment, ...payload }: TField) => {
      saveApp({
        ...payload,
        logo: { key },
        environment: +environment.value
      })
    },
    [saveApp]
  )

  const onRedirectAppBasicSetting = useCallback(() => {
    redirect(ROUTE_NAMES.APP_BASIC_SETTING, { params: { appId } })
  }, [appId])

  const onGenerate = useCallback(() => {
    if (!client_id) return
    generatePublicKeyAsync({ client_id, force: false }).then(() => {
      onRedirectAppBasicSetting()
      closeModal()
    })
  }, [generatePublicKeyAsync, client_id, onRedirectAppBasicSetting, closeModal])

  return (
    <>
      <Button onClick={openModal}>
        <Icon namespace="Add" />
        <span className="whitespace-nowrap">Create Application</span>
      </Button>

      <Modal {...modalActions}>
        {state === STATE.CREATE_APP && (
          <CreateAppForm
            onClose={closeModal}
            loading={savingApp}
            onSubmit={onCreate}
          />
        )}

        {state === STATE.GENERATE_PUBLIC_KEY && (
          <GeneratePublicKeyPrompt
            onGenerate={onGenerate}
            onNotNow={onRedirectAppBasicSetting}
            loading={generatingPublicKey}
          />
        )}
      </Modal>
    </>
  )
}

export default CreateApplication
