import { z } from 'zod'

export const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  environment: z
    .object({
      value: z.string().trim(),
      label: z.string().trim()
    })
    .refine(d => !!d.value.trim(), 'Requried!'),
  key: z.string().trim(),
  brand: z.string().trim()
})

export type TField = z.infer<typeof schema>

export const defaultValues: TField = {
  name: '',
  environment: {
    label: '',
    value: ''
  },
  key: '',
  brand: 'captain'
}
