import Highcharts from 'highcharts'
import HighchartsReact, {
  HighchartsReactRefObject
} from 'highcharts-react-official'
import mapDataWorld from '@highcharts/map-collection/custom/world.topo.json'

// Import the map module
import MapModule from 'highcharts/modules/map'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { markedDefaultKey } from '@genie-fintech/ui/style/theme/colors/functions'
import { useCallback, useRef } from 'react'
import { zoomContainer } from './styles.css'
import { Button, ButtonProps } from '@genie-fintech/ui/components'
import { Minus, Plus, RefreshCcw } from 'lucide-react'

// Initialize the map module
MapModule(Highcharts)

const { colors } = themeVars

const zoomButtonVariants: ButtonProps['styleVariants'] = {
  kind: 'neutral',
  type: 'outlined',
  size: 'small',
  category: 'icon',
  inline: true
}

const MapView = ({
  data
}: {
  data: { display_name: string; count: number; name: string }[]
}) => {
  const chartRef = useRef<HighchartsReactRefObject>(null)

  const zoom = useCallback(
    (
      value?: number,
      xProjected?: number,
      yProjected?: number,
      chartX?: number,
      chartY?: number
    ) => {
      const chart = chartRef.current?.chart
      if (chart) {
        chart.mapZoom(value, xProjected, yProjected, chartX, chartY)
      }
    },
    []
  )

  const zoomIn = useCallback(() => {
    zoom(0.7)
  }, [zoom])

  const zoomOut = useCallback(() => {
    zoom(1.3)
  }, [zoom])

  const resetZoom = useCallback(() => {
    zoom(undefined)
  }, [zoom])

  const options = {
    accessibility: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    chart: {
      backgroundColor: colors.area.high
    },
    mapNavigation: {
      enabled: true,
      enableButtons: false
    },
    mapView: {
      projection: {
        name: 'WebMercator'
      }
    },
    colorAxis: {
      visible: false,
      min: 0,
      minColor: colors.primary[0],
      maxColor: colors.primary[markedDefaultKey],
      stops: [
        [0, colors.primary[0]],
        [0.25, colors.primary[20]],
        [0.5, colors.primary[40]],
        [0.75, colors.primary[60]],
        [1, colors.primary[80]]
      ]
    },
    plotOptions: {
      map: {
        borderColor: colors.neutral[50],
        nullColor: colors.neutral[10]
      }
    },
    series: [
      {
        data: data.map(({ name, count }) => ({
          'hc-key': name.toLocaleLowerCase(),
          value: count
        })),
        mapData: mapDataWorld,
        joinBy: 'hc-key',
        name: 'Visitors from',
        states: {
          hover: {
            color: colors.primary[80]
          }
        },
        dataLabels: {
          enabled: false,
          format: '{point.name}'
        }
      }
    ]
  }

  return (
    <div style={{ position: 'relative' }}>
      <HighchartsReact
        ref={chartRef}
        highcharts={Highcharts}
        constructorType={'mapChart'}
        options={options}
      />

      <div className={zoomContainer}>
        <Button styleVariants={zoomButtonVariants} onClick={zoomIn}>
          <Plus size={20} />
        </Button>
        <Button styleVariants={zoomButtonVariants} onClick={zoomOut}>
          <Minus size={20} />
        </Button>
        <Button styleVariants={zoomButtonVariants} onClick={resetZoom}>
          <RefreshCcw size={20} />
        </Button>
      </div>
    </div>
  )
}

export default MapView
