import { cn } from '$app/utils'
import UpdatePasswordBlock from '$blocks/UpdatePassword'
import Breadcrumb from '$components/Breadcrumb/v2'
import {
  useRedirectPrompt,
  useRedirectProxy,
  useRouteSummary
} from '$contexts/RouteContext/hooks'
import {
  useGlobalDashboardUserService,
  useGlobalServiceUserService,
  useUserService
} from '$hooks/services'
import { usePasswordPolicyStore } from '$hooks/stores/usePasswordPolicyStore'
import LayoutWithBackground from '$layouts/LayoutWithBackground'
import PreviewScreen from '$pages/AppUser/Create/PreviewScreen'
import { redirect, ROUTE_NAMES } from '$router/config'
import { defaultBackground, shadow } from '$styles/common.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useMount, useToggle } from 'ahooks'
import { FormEvent, useCallback, useMemo, useState } from 'react'
import { toast } from 'sonner'

const { colors } = themeVars

const UpdatePassword = () => {
  const {
    route: { params, queryParams }
  } = useRouteSummary()

  const { userId } = params

  const { source, appId } = queryParams

  const [updatedPwd, setUpdatedPwd] = useState('')

  const [isNextSignIn, { toggle }] = useToggle(!!source)

  const [hasUpdatedPwd, setHasUpdatedPwd] = useState(false)

  const proxyRedirect = useRedirectProxy()

  const { updatePasswordAsync, updatingPassword } = useUserService()

  const { fetchServiceUserInfo, serviceUserInfo } =
    useGlobalServiceUserService()

  const { fetchGlobalDashboardUserDetail, dashboardUser } =
    useGlobalDashboardUserService()

  const shouldPrompt = !hasUpdatedPwd && !!updatedPwd

  useRedirectPrompt({ shouldPrompt })

  useMount(() => {
    if (!userId) return

    if (!source) {
      fetchGlobalDashboardUserDetail(userId)
      return
    }

    fetchServiceUserInfo({ userId })
  })

  const passwordRules = usePasswordPolicyStore(state => state.passwordRules)

  const isPwdValid =
    !!updatedPwd && passwordRules.every(d => d.regex.test(updatedPwd))

  const redirectInfo: Parameters<typeof redirect> = useMemo(() => {
    if (appId && source === ROUTE_NAMES.APP_USER_DETAIL) {
      return [ROUTE_NAMES.APP_USER_DETAIL, { params: { appId, userId } }]
    }

    if (source === ROUTE_NAMES.GLOBAL_APP_USER_DETAIL) {
      return [
        ROUTE_NAMES.GLOBAL_SERVICE_USER_PASSWORD_SETTING,
        { params: { serviceUserId: userId } }
      ]
    }

    return [
      ROUTE_NAMES.GLOBAL_DASHBOARD_USER_DETAIL,
      { params: { dashboardUserId: userId } }
    ]
  }, [appId, source, userId])

  const onChangePassword = useCallback((pwd: string) => {
    setUpdatedPwd(pwd)
  }, [])

  const onCancel = useCallback(() => {
    proxyRedirect(...redirectInfo)
  }, [proxyRedirect, redirectInfo])

  const onSuccess = useCallback(() => {
    setHasUpdatedPwd(true)
  }, [])

  const onSaveChanges = useCallback(() => {
    if (!userId) return

    if (!isPwdValid) {
      toast.error('Password must be valid with all password policies')
      return
    }

    return updatePasswordAsync({
      isAdmin: !source,
      userId,
      password: updatedPwd,
      should_reset_password: isNextSignIn
    }).then(onSuccess)
  }, [
    isPwdValid,
    source,
    userId,
    updatePasswordAsync,
    updatedPwd,
    isNextSignIn,
    onSuccess
  ])

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      onSaveChanges()
    },
    [onSaveChanges]
  )

  return (
    <>
      <Breadcrumb
        category={(() => {
          if (source === ROUTE_NAMES.APP_USER_DETAIL) return ROUTE_NAMES.APPS
          if (source === ROUTE_NAMES.GLOBAL_APP_USER_DETAIL)
            return ROUTE_NAMES.GLOBAL_APP_USERS
          return ROUTE_NAMES.GLOBAL_DASHBOARD_USERS
        })()}
        data={(() => {
          const arr: { name: string; path?: ROUTE_NAMES }[] = [
            { name: 'User Details' }
          ]
          if (source === ROUTE_NAMES.APP_USER_DETAIL)
            arr.unshift({ name: 'App Details', path: ROUTE_NAMES.APP_USERS })
          return arr
        })()}
      />

      <LayoutWithBackground
        removeNoiseOverlay
        style={{
          position: 'fixed',
          height: 216,
          background: colors.absolute.dark
        }}
      />

      {!hasUpdatedPwd && (
        <>
          <form
            onSubmit={onSubmit}
            className="flex flex-col w-full max-w-[1056px] mx-auto relative"
          >
            <UpdatePasswordBlock
              pwd={updatedPwd}
              onChangePwd={onChangePassword}
              nextSignIn={isNextSignIn}
              onChangeNextSignIn={toggle}
              showNextSignIn={!!source}
            />

            <article
              className={cn(
                'flex fixed inset-x-0 bottom-0 px-2 py-2.5 border-t',
                defaultBackground,
                shadow.big
              )}
              style={{ borderColor: colors.neutral[10] }}
            >
              <article className="flex justify-end w-full max-w-[1056px] gap-2 mx-auto">
                <Button
                  disabled={updatingPassword}
                  styleVariants={{ type: 'text' }}
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  disabled={!updatedPwd || updatingPassword}
                >
                  {updatingPassword && <Spinner />}
                  Reset Password
                </Button>
              </article>
            </article>
          </form>
        </>
      )}

      {hasUpdatedPwd && (
        <article className="flex flex-col w-full max-w-[1056px] mx-auto relative">
          <PreviewScreen
            name={serviceUserInfo?.name ?? dashboardUser?.name ?? ''}
            email={serviceUserInfo?.email ?? dashboardUser?.email ?? ''}
            password={updatedPwd}
            userId={userId ?? ''}
            userType={source ? 'service' : 'dashboard'}
          />
        </article>
      )}
    </>
  )
}

export default UpdatePassword
