import { warningIconBox } from '$styles/common.css'
import { Key } from 'lucide-react'
import { FC } from 'react'
import {
  flexColumn,
  generatePublicKeyContainer,
  generatePublicKeyDescription,
  generatePublicKeyFooter,
  generatePublicKeyTitle
} from './styles.css'
import { Button, Spinner } from '@genie-fintech/ui/components'

interface IProps {
  onGenerate: VoidFunction
  onNotNow: VoidFunction
  loading: boolean
}

const GeneratePublicKeyPrompt: FC<IProps> = ({
  onGenerate,
  onNotNow,
  loading
}) => {
  return (
    <article className={flexColumn}>
      <main className={generatePublicKeyContainer}>
        <span className={warningIconBox}>
          <Key size={20} />
        </span>

        <article className={flexColumn} style={{ gap: 4 }}>
          <p className={generatePublicKeyTitle}>Generate Public Key</p>
          <p className={generatePublicKeyDescription}>
            A secure public key to share safely without exposing private data.
          </p>
        </article>
      </main>
      <footer className={generatePublicKeyFooter}>
        <Button
          styleVariants={{ size: 'small', kind: 'neutral', type: 'outlined' }}
          onClick={onGenerate}
          disabled={loading}
        >
          {loading && <Spinner />}
          Generate
        </Button>

        <Button
          styleVariants={{ size: 'small' }}
          onClick={onNotNow}
          disabled={loading}
        >
          Not Now
        </Button>
      </footer>
    </article>
  )
}

export default GeneratePublicKeyPrompt
