import EnterTransitionBlock from '$components/EnterTransitionBlock'
import MainHeader from '$layouts/GlobalUserLayout/components/MainHeader'
import {
  innerMainContainerStyle,
  mainSubContainerStyle
} from '$layouts/GlobalUserLayout/styles.css'
import { USER_TYPE } from '$layouts/GlobalUserLayout/types'
import { title } from '@genie-fintech/ui/style/typography'
import { flexColumn, header, line } from './styles.css'
import { customTableContainer, description } from '$styles/common.css'
import {
  status,
  table,
  tableBody,
  tableContainerInner
} from '@genie-fintech/ui/style/element'
import StopAllBlock from './StopAllBlock'
import FailedLoginRow from './FailedLoginRow'
import { useUserId } from '$layouts/GlobalUserLayout/hooks/useUserId'
import { useMount, useRequest } from 'ahooks'
import {
  deleteAllFailedLogins,
  FAILED_LOGIN,
  getFailedLogins
} from '$services/api'
import { useCallback, useState } from 'react'
import Loading from '$components/Loading'
import { toast } from 'sonner'
import UnlockHistory from './UnlockHistory'

const GlobalServiceUserAttackProtection = ({
  userType
}: {
  userType: USER_TYPE
}) => {
  const { userId } = useUserId(userType)

  const [failedLogins, setFailedLogins] = useState<FAILED_LOGIN[]>([])

  const fetchFailedLoginsRequest = useRequest(getFailedLogins, { manual: true })

  const deleteAllFailedLoginsRequest = useRequest(deleteAllFailedLogins, {
    manual: true
  })

  useMount(() => {
    if (!userId) return
    fetchFailedLoginsRequest.runAsync(userId).then(({ data }) => {
      setFailedLogins(data)
    })
  })

  const onRemoveFailedLogin = useCallback((failedLoginId: string) => {
    setFailedLogins(prev => prev.filter(d => d.id !== failedLoginId))
  }, [])

  const onStopAll = useCallback(() => {
    if (!userId) return
    deleteAllFailedLoginsRequest.runAsync(userId).then(() => {
      toast.success('Stopped all lock periods!')
      setFailedLogins([])
    })
  }, [userId, deleteAllFailedLoginsRequest])

  const isEmpty = !failedLogins.length

  const hasMoreThanOne = failedLogins.length > 1

  if (fetchFailedLoginsRequest.loading) return <Loading />

  return (
    <EnterTransitionBlock className={innerMainContainerStyle}>
      <MainHeader
        title="ATTACK PROTECTION"
        desc="Access guidelines to ensure secure and seamless login."
      />

      <article className={mainSubContainerStyle}>
        <article className={header}>
          <article className={flexColumn} style={{ gap: 4 }}>
            <p className={title.two}>TEMPORARY LOCK PERIOD!</p>
            <p className={description}>
              Login can be temporary locked due to the activity that violates
              our login threshold. However you can refresh the session again.
            </p>
          </article>

          <UnlockHistory userId={userId} />
        </article>

        {isEmpty && (
          <article>
            <span className={status({ kind: 'grey', type: 'faint' })}>
              There is no temporary lock period activity!
            </span>
          </article>
        )}

        {!isEmpty && (
          <article className={customTableContainer}>
            <main className={tableContainerInner}>
              <table className={table()}>
                <tbody className={tableBody}>
                  {failedLogins.map((failedLogin, key) => (
                    <FailedLoginRow
                      key={key}
                      userId={userId}
                      data={failedLogin}
                      onRemoveFailedLogin={onRemoveFailedLogin}
                    />
                  ))}
                </tbody>
              </table>
            </main>
          </article>
        )}

        {hasMoreThanOne && (
          <>
            <hr className={line} />

            <StopAllBlock
              onStopAll={onStopAll}
              loading={deleteAllFailedLoginsRequest.loading}
            />
          </>
        )}
      </article>
    </EnterTransitionBlock>
  )
}

export default GlobalServiceUserAttackProtection
