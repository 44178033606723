import { cn } from '$app/utils'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { redirect, ROUTE_NAMES } from '$router/config'
import { defaultBackground, borderNeutral20 } from '$styles/common.css'
import { Button } from '@genie-fintech/ui/components'
import { useCallback } from 'react'
import Header from './components/Header'
import DragUpload from './components/DragUpload'
import { IMPORT_TYPE } from './types'
import FileUploadButton from './components/FileUploadButton'
import { useUploadAndImportFile } from './hooks/useUploadAndImportFile'
import { themeVars } from '@genie-fintech/ui/style/theme'
import ImportInfo from './components/ImportInfo'
import InvalidRecords from './components/InvalidRecords'

const { colors } = themeVars

const BACK_ROUTE: Record<IMPORT_TYPE, ROUTE_NAMES> = {
  role: ROUTE_NAMES.APP_ROLES,
  permission: ROUTE_NAMES.APP_PERMISSIONS,
  user: ROUTE_NAMES.APP_USERS
}

const CSVImport = ({ importType }: { importType: IMPORT_TYPE }) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { uploadedFileData, uploading, onSelectFile, invalidRecords } =
    useUploadAndImportFile({ appId, importType })

  const handleOnBack = useCallback(() => {
    redirect(BACK_ROUTE[importType], { params })
  }, [importType, params])

  const hasImported = !uploading && !!uploadedFileData

  return (
    <article
      className={cn(
        'flex flex-col px-12 py-7 rounded-lg gap-6 flex-1 max-w-[1056px] mx-auto',
        defaultBackground,
        borderNeutral20
      )}
    >
      <Header type={importType} />

      <DragUpload
        selectedFile={uploadedFileData}
        onSelectFile={onSelectFile}
        loading={uploading}
      />

      {hasImported && (
        <article
          className={cn(
            'flex flex-col gap-2.5 p-4 rounded-lg border',
            defaultBackground
          )}
          style={{ borderColor: colors.neutral[10] }}
        >
          <ImportInfo records={invalidRecords} />

          <InvalidRecords records={invalidRecords} />
        </article>
      )}

      <article className="flex justify-between gap-2 items-center">
        <Button
          styleVariants={{ kind: 'neutral', type: 'outlined' }}
          onClick={handleOnBack}
        >
          Back to Listing
        </Button>

        {hasImported && (
          <FileUploadButton
            btnText="Click to add more file!"
            onChangeFile={e => onSelectFile(e.currentTarget.files)}
          />
        )}
      </article>
    </article>
  )
}

export default CSVImport
