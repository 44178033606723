import { useInert } from '$hooks/actions'
import { useBoolean } from 'ahooks'

type Props = {
  closeOnClickOutside: boolean
  isFocusMode: boolean
}

export const useModal = (props?: Partial<Props>) => {
  const [open, { setTrue: openModal, setFalse: closeModal }] = useBoolean()

  const containerRef = useInert(!open)

  const { closeOnClickOutside = true, isFocusMode } = { ...props }

  const onOverlayClick = (e: React.MouseEvent) => {
    e.stopPropagation()

    if (!closeOnClickOutside) return undefined

    if (e.target === e.currentTarget) {
      closeModal()
    }
  }

  return {
    containerRef,
    closeOnClickOutside,
    isFocusMode,
    onOverlayClick,
    open,
    openModal,
    closeModal
  }
}
