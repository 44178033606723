import MainHeader from '$layouts/GlobalUserLayout/components/MainHeader'
import {
  innerMainContainerStyle,
  mainSubContainerStyle
} from '$layouts/GlobalUserLayout/styles.css'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { footnote, title } from '@genie-fintech/ui/style/typography'
import { useToggle } from 'ahooks'
import { PenLine } from 'lucide-react'
import { commonFieldBlock } from './styles.css'
import { useUserInfoStore } from '$layouts/GlobalUserLayout/hooks/useUserInfoStore'
import { cn, getDateOfBirth } from '$app/utils'
import { useCallback, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import Select from '$components/Select'
import { GENDER_OPTIONS } from '$app/constants'
import {
  INITIAL_DEFAULT_VALUES,
  LABEL,
  outlineNeutralButtonVariant,
  schema,
  TFormValues
} from './constants'
import EnterTransitionBlock from '$components/EnterTransitionBlock'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import {
  useGlobalServiceUserService,
  usePasswordSettingValues
} from '$hooks/services'
import { useShallow } from 'zustand/react/shallow'
import { toast } from 'sonner'
import LoginUserType from '../../../components/LoginUserType'
import { SERVICE_USER_TYPE } from '$services/api'
import LoginUserTypeDialog from './LoginUserTypeDialog'
import TimezoneSetting from './TimezoneSetting'
import { TTimezone } from '$contexts/AuthContext/types'
import ChangeEmailSetting from './ChangeEmailSetting'
import ChangePhoneSetting from './ChangePhoneSetting'

const { colors } = themeVars

const Info = ({ label, value }: { label: string; value?: string }) => {
  return (
    <article className={cn('flex-1 flex flex-col gap-0.5')}>
      <p className={footnote.two} style={{ color: colors.neutral[60] }}>
        {label}
      </p>
      <p className={title.three} style={{ color: colors.text.light }}>
        {value || '-'}
      </p>
    </article>
  )
}

const GlobalServiceUserDetail = () => {
  const [isEdit, { toggle: toggleEdit }] = useToggle()

  const [isModalOpen, { toggle: toggleModal }] = useToggle()

  const {
    updateServiceUserAsync,
    updatingServiceUser,
    updateServiceUserTypeAsync,
    updatingServiceUserType,
    updateServiceUserTimezoneAsync,
    updatingServiceUserTimezone
  } = useGlobalServiceUserService()

  const {
    fetchCurrentPasswordSettingValuesAsync,
    fetchingCurrentPasswordSettingValues,
    currentPasswordSettingValues,
    fetchDefaultPasswordSettingValuesAsync,
    fetchingDefaultPasswordSettingValues,
    defaultPasswordSettingValues
  } = usePasswordSettingValues()

  const { userInfo, updateUserInfo } = useUserInfoStore(
    useShallow(state => ({
      userInfo: state.userInfo,
      updateUserInfo: state.updateUserInfo
    }))
  )

  const {
    id,
    name,
    date_of_birth,
    gender,
    address,
    user_type: currentLoginUserType = SERVICE_USER_TYPE.INTERNAL
  } = userInfo ?? {}

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema)
  })

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty }
  } = methods

  const _gender = useMemo(
    () =>
      GENDER_OPTIONS.find(d => d.value === gender) ?? {
        label: '',
        value: ''
      },
    [gender]
  )

  const onEdit = useCallback(() => {
    reset({
      name: name ?? '',
      date_of_birth: date_of_birth ?? '',
      gender: _gender,
      address: address ?? ''
    })

    toggleEdit()
  }, [reset, name, date_of_birth, _gender, address, toggleEdit])

  const onCancel = useCallback(() => {
    toggleEdit()
    reset(INITIAL_DEFAULT_VALUES)
  }, [toggleEdit, reset])

  const onSave = useCallback(
    async (values: TFormValues) => {
      if (!id) return

      const { gender, ...rest } = values

      const payload = {
        gender: gender.value,
        ...rest
      }

      await updateServiceUserAsync(id, payload)
        .then(() => {
          toast.success(`Successfully Updated!`)
          if (!userInfo) return
          updateUserInfo({ ...userInfo, ...payload })
        })
        .then(toggleEdit)
    },
    [id, userInfo, updateUserInfo, updateServiceUserAsync, toggleEdit]
  )

  const onContinue = useCallback(
    async (
      newLoginUserType: SERVICE_USER_TYPE,
      isModifyLoginRules: boolean,
      callbackFn?: VoidFunction
    ) => {
      if (!id) return
      await updateServiceUserTypeAsync(id, {
        is_internal: newLoginUserType === SERVICE_USER_TYPE.INTERNAL,
        modify_login_rules: isModifyLoginRules
      }).then(() => {
        toast.success('Login user type updated!')
        if (userInfo)
          updateUserInfo({ ...userInfo, user_type: newLoginUserType })
        callbackFn?.()
      })
    },
    [id, userInfo, updateServiceUserTypeAsync, updateUserInfo]
  )

  const onChangeLoginUserType = useCallback(async () => {
    if (!id) return
    toggleModal()
    fetchCurrentPasswordSettingValuesAsync({ userId: id })
    fetchDefaultPasswordSettingValuesAsync()
  }, [
    id,
    fetchCurrentPasswordSettingValuesAsync,
    fetchDefaultPasswordSettingValuesAsync,
    toggleModal
  ])

  const onUpdateTimezone = useCallback(
    async (timezone: TTimezone[number], callBackFn: VoidFunction) => {
      if (!id) return
      await updateServiceUserTimezoneAsync(id, timezone.key).then(() => {
        toast.success('Timezone updated!')
        if (userInfo) updateUserInfo({ ...userInfo, timezone: timezone.key })
        callBackFn()
      })
    },
    [id, updateServiceUserTimezoneAsync, userInfo, updateUserInfo]
  )

  return (
    <>
      <EnterTransitionBlock className={innerMainContainerStyle}>
        <MainHeader
          title="USER DETAILS"
          desc="Manage and review user accounts for efficient system administration."
        />

        <article className="flex flex-col gap-1.5">
          <form
            className={mainSubContainerStyle}
            onSubmit={handleSubmit(onSave)}
          >
            <header className="flex items-center gap-3">
              <p
                className={title.six}
                style={{ color: colors.text.light, flex: 1 }}
              >
                Some of the user informations can be editable.
              </p>

              {isEdit && (
                <>
                  <Button
                    styleVariants={outlineNeutralButtonVariant}
                    onClick={onCancel}
                    disabled={updatingServiceUser}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    styleVariants={{ size: 'small' }}
                    disabled={updatingServiceUser || !isDirty}
                  >
                    {updatingServiceUser && <Spinner />}
                    Save Changes
                  </Button>
                </>
              )}

              {!isEdit && (
                <Button
                  styleVariants={outlineNeutralButtonVariant}
                  onClick={onEdit}
                >
                  <PenLine size={16} />
                  Edit
                </Button>
              )}
            </header>

            <main className={commonFieldBlock}>
              {!isEdit && (
                <EnterTransitionBlock className="flex-1 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-8">
                  <Info label={LABEL.USERNAME} value={name} />
                  <Info
                    label={LABEL.DOB}
                    value={getDateOfBirth(date_of_birth)}
                  />
                  <Info label={LABEL.GENDER} value={_gender.label} />
                  <Info label={LABEL.ADDRESS} value={address} />
                </EnterTransitionBlock>
              )}

              {isEdit && (
                <EnterTransitionBlock className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <article>
                    <BaseHookFieldText
                      name="name"
                      control={control}
                      label={LABEL.USERNAME}
                      required
                    />
                  </article>

                  <article>
                    <BaseHookFieldText
                      control={control}
                      name="date_of_birth"
                      label={LABEL.DOB}
                      inputProps={{ type: 'date' }}
                    />
                  </article>

                  <Controller
                    name="gender"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <article className="flex flex-col gap-y-2">
                          <label className="text-sm font-medium">Gender</label>
                          <Select
                            {...field}
                            options={GENDER_OPTIONS}
                            error={!!error?.message}
                          />
                          {error?.message && (
                            <p className="text-xs text-[--colors-danger-default]">
                              {error.message}
                            </p>
                          )}
                        </article>
                      )
                    }}
                  />

                  <article>
                    <Textarea
                      name="address"
                      control={control}
                      label={LABEL.ADDRESS}
                    />
                  </article>
                </EnterTransitionBlock>
              )}
            </main>
          </form>

          <LoginUserType
            value={userInfo?.user_type ?? SERVICE_USER_TYPE.INTERNAL}
            onChange={onChangeLoginUserType}
          />

          <TimezoneSetting
            timezone={userInfo?.timezone ?? ''}
            loading={updatingServiceUserTimezone}
            onUpdateTimezone={onUpdateTimezone}
          />

          <ChangePhoneSetting />

          <ChangeEmailSetting />
        </article>
      </EnterTransitionBlock>

      <LoginUserTypeDialog
        modalOpen={isModalOpen}
        currentLoginUserType={currentLoginUserType}
        onCancel={toggleModal}
        onContinue={onContinue}
        fetching={
          fetchingCurrentPasswordSettingValues ||
          fetchingDefaultPasswordSettingValues
        }
        updating={updatingServiceUserType}
        currentPasswordSettingValues={currentPasswordSettingValues}
        defaultPasswordSettingValues={defaultPasswordSettingValues}
      />
    </>
  )
}

export default GlobalServiceUserDetail
