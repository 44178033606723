import { TAppBasicSetting } from '$services/api'
import { create } from 'zustand'

interface IAppDetailState {
  appDetail?: TAppBasicSetting
  updateAppDetail: (appDetail: IAppDetailState['appDetail']) => void
}

export const useAppDetailStore = create<IAppDetailState>()(set => ({
  appDetail: undefined,
  updateAppDetail: appDetail => set({ appDetail })
}))
