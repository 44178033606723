import AppCard from '$components/AppCard'
import Breadcrumb from '$components/Breadcrumb/v2'
import EmptyData from '$components/EmptyData'
import Loading from '$components/Loading'
import { useApiListingParams, usePermissions } from '$hooks/actions'
import { useAppService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import { TAppListResponse } from '$services/api'
import { Spinner } from '@genie-fintech/ui/components'
import { trueOrUndefined } from '@genie-fintech/ui/functions'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { useInViewport } from 'ahooks'
import { useEffect, useRef, useState } from 'react'
import {
  Container,
  DescStyle,
  HeaderContainer,
  TitleContainer,
  TitleItem
} from './styles.css'
import SearchBox from '$components/SearchBox'
import { PERMISSIONS } from '$app/constants'
import CreateApplication from '$blocks/CreateApplication'
import { useModal } from '$components/Modal/hooks/useModal'

const PER_PAGE = 24

const AppList = () => {
  const [appList, setAppList] = useState<TAppListResponse['data']>([])

  const isMounted = useIsMounted()

  const { checkPermissions } = usePermissions()

  const modalActions = useModal({
    closeOnClickOutside: false,
    isFocusMode: true
  })

  const CAN_CREATE_APP = checkPermissions([PERMISSIONS.CREATE_APPLICATION])

  const {
    fetchAppListAsync,
    fetchingAppList,
    app: { meta }
  } = useAppService()

  const { last_page = 1 } = meta ?? {}

  const targetRef = useRef(null)

  const { searchValue, updateSearchValue, debouncedSearchValue, pagerProps } =
    useApiListingParams({ total: meta?.total, perPage: PER_PAGE })

  const { currentPage, jump } = pagerProps

  const initialLoading = !appList.length && fetchingAppList

  const hasExtra = !!appList.length && appList.length !== meta?.total

  const isEmpty = !appList.length && !fetchingAppList

  useInViewport(targetRef, {
    callback: entry => {
      if (fetchingAppList) return

      if (entry.isIntersecting) {
        jump(Math.min(currentPage + 1, last_page))
      }
    }
  })

  useEffect(() => {
    fetchAppListAsync({
      page: currentPage,
      per_page: PER_PAGE,
      q: debouncedSearchValue
    }).then(({ data }) =>
      setAppList(prev => (currentPage === 1 ? data : [...prev, ...data]))
    )
  }, [fetchAppListAsync, currentPage, debouncedSearchValue])

  return (
    <>
      <Breadcrumb category={ROUTE_NAMES.APPS} />

      <section className={Container} data-ready={trueOrUndefined(isMounted)}>
        <header className={HeaderContainer}>
          <article>
            <p className={TitleContainer}>
              <span>Applications</span>
              <span className={TitleItem} data-ready={meta?.total}>
                -
              </span>
              <span className={TitleItem} data-ready={meta?.total}>
                {meta?.total}
              </span>
            </p>

            <p className={DescStyle}>
              Setup a mobile, web application to use Carro SSO for
              Authentication.
            </p>
          </article>

          <article className="flex flex-wrap gap-2 items-center">
            <article className="flex w-[280px]">
              <SearchBox
                searchValue={searchValue}
                onChangeSearchValue={updateSearchValue}
                placeholder="Search Applications..."
              />
            </article>

            {CAN_CREATE_APP && <CreateApplication {...modalActions} />}
          </article>
        </header>

        <main className="flex-1 flex flex-col">
          {initialLoading && <Loading />}

          {isEmpty && (
            <article className="flex-1 grid place-items-center">
              <EmptyData type="application" onClick={modalActions.openModal} />
            </article>
          )}

          <article className="grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {appList.map(appData => (
              <AppCard key={appData.id} data={appData} />
            ))}
          </article>

          {hasExtra && (
            <article className="flex justify-center py-20">
              <article ref={targetRef} className="flex justify-center gap-x-2">
                <Spinner />
              </article>
            </article>
          )}
        </main>
      </section>
    </>
  )
}

export default AppList
