import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppPermissionService } from '$hooks/services'
import { TAppPermissionListResponse, TID } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  defaultBackground,
  borderNeutral20
} from '$styles/common.css'
import { Checkbox } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { useEffect } from 'react'
import { cn } from '$app/utils'
import Pagination from '$components/Pagination'
import { format } from 'date-fns'

const columns: {
  key: keyof Omit<TAppPermissionListResponse['data'][number], 'id'>
  value: string
}[] = [
  { key: 'name', value: 'PERMISSION NAME' },
  { key: 'module_name', value: 'MODULE NAME' },
  { key: 'created_at', value: 'CREATED AT' }
]

interface IProps {
  onRowClick?: (id: TID) => void
  onChange?: (id: TID[]) => void
  permissions: TID[]
}

const RolePermissions = ({ onRowClick, permissions, onChange }: IProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const {
    fetchAppPermissionList,
    fetchingAppPermissionList,
    permissions: { list, meta }
  } = useAppPermissionService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId) return
    fetchAppPermissionList({
      page,
      per_page: perPage,
      application_id: appId,
      q: debouncedSearchValue
    })
  }, [appId, fetchAppPermissionList, page, perPage, debouncedSearchValue])

  return (
    <article
      className={cn(
        'flex flex-col rounded-lg gap-6 px-12 py-7',
        defaultBackground,
        borderNeutral20
      )}
    >
      <article className="flex flex-col gap-1">
        <p className="font-semibold">ASSIGNED MODULES & PERMISSIONS</p>
        <p className="text-xs text-[--colors-neutral-50]">
          Configure and review modules and their associated permissions for
          precise access control.
        </p>
      </article>

      <article className="flex flex-col gap-2">
        <article className="flex gap-2 items-center justify-between">
          <article>
            <BaseText
              affix={{
                pre: <Icon namespace="Search" width={16} />,
                post: searchValue ? (
                  <button onClick={() => updateSearchValue('')}>
                    <Icon namespace="Cross" width={18} />
                  </button>
                ) : undefined
              }}
              inputProps={{
                type: 'text',
                value: searchValue,
                onChange: e => updateSearchValue(e.currentTarget.value),
                placeholder: 'Search here...'
              }}
            />
          </article>

          {!!meta?.total && (
            <p className="text-[--colors-text-disabled] text-sm font-medium">
              {meta.total} PERMISSION{meta.total > 1 && 'S'}
            </p>
          )}
        </article>

        <article className={customTableContainer}>
          <main className={tableContainerInner}>
            <table className={table({ separator: 'line' })}>
              <thead className={customTableHead}>
                <tr className={tableRow}>
                  <th style={{ width: 0 }} className={customTableCell}>
                    <Checkbox
                      disabled={!onChange}
                      boxProps={{
                        checked:
                          !!list.length &&
                          list.every(d => permissions.includes(d.id)),
                        onCheckedChange: checked => {
                          if (checked) {
                            onChange?.([...permissions, ...list.map(v => v.id)])
                            return
                          }

                          onChange?.(
                            permissions.filter(
                              d => !list.map(v => v.id).includes(+d)
                            )
                          )
                        }
                      }}
                    />
                  </th>
                  <th className={customTableCell} style={{ width: 0 }}>
                    NO
                  </th>
                  {columns.map((col, key) => (
                    <th key={key} className={customTableCell}>
                      {col.value}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody className={tableBody}>
                {list.map((data, rowKey) => (
                  <tr
                    key={rowKey}
                    className={cn(tableRow, onRowClick && 'cursor-pointer')}
                    onClick={() => onRowClick?.(data.id)}
                  >
                    <td className={customTableCell}>
                      <Checkbox
                        disabled={!onChange}
                        boxProps={{
                          checked: permissions.includes(data.id),
                          onCheckedChange: checked => {
                            if (checked) {
                              onChange?.([...permissions, data.id])
                              return
                            }

                            onChange?.(permissions.filter(d => d !== data.id))
                          }
                        }}
                      />
                    </td>
                    <td className={customTableCell}>
                      {(page - 1) * perPage + rowKey + 1}
                    </td>
                    {columns.map((col, colKey) => (
                      <td key={colKey} className={customTableCell}>
                        {(() => {
                          if (col.key === 'created_at') {
                            return data.created_at
                              ? format(data.created_at, 'dd MMM yyyy')
                              : '-'
                          }

                          return data[col.key] ?? 'N/A'
                        })()}
                      </td>
                    ))}
                  </tr>
                ))}

                {!list.length && !fetchingAppPermissionList && (
                  <tr className={tableRow}>
                    <td
                      colSpan={columns.length + 2}
                      style={{ textAlign: 'center' }}
                      className={customTableCell}
                    >
                      NO DATA
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {fetchingAppPermissionList && <Loading />}
          </main>
        </article>
      </article>

      {!!list.length && (
        <footer className="flex justify-end bg-[--colors-area-high] p-4">
          <Pagination meta={meta} pagerProps={pagerProps} />
        </footer>
      )}
    </article>
  )
}

export default RolePermissions
