import { cn } from '$app/utils'
import PublishUnpublishBadge from '$components/PublishUnpublishBadge'
import PublishUnpublishButton from '$components/PublishUnpublishButton'
import { APP_STATUS } from '$components/PublishUnpublishButton/types'
import { useAppDetailStore } from '$hooks/stores'
import { borderNeutral20, description, title } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Lock } from 'lucide-react'
import { useMemo } from 'react'

const { colors } = themeVars

const PublishAppSetting = () => {
  const is_published = useAppDetailStore(state => state.appDetail?.is_published)

  const current_app_status = useMemo(() => {
    if (is_published) return APP_STATUS.PUBLISH
    return APP_STATUS.UNPUBLISH
  }, [is_published])

  return (
    <article
      className={cn('flex p-6 gap-6 rounded-lg items-center', borderNeutral20)}
      style={{ background: colors.area.low }}
    >
      <Lock
        className="shrink-0"
        size={36}
        style={{ color: colors.text.disabled }}
      />

      <article className="flex-1 flex flex-wrap gap-4 items-center">
        <article className="flex-1 flex flex-col gap-1">
          <p className={title['type2']}>PUBLISH APPLICATION SETTING</p>
          <p className={description}>
            Publish application settings to manage and configure system
            preferences efficiently.
          </p>
        </article>

        <PublishUnpublishBadge status={current_app_status} />

        <PublishUnpublishButton current_status={current_app_status} />
      </article>
    </article>
  )
}

export default PublishAppSetting
