import { cn, formatDateWithGMT } from '$app/utils'
import Breadcrumb from '$components/Breadcrumb/v2'
import Loading from '$components/Loading'
import ModKey from '$components/ModKey'
import Pagination from '$components/Pagination'
import { useApiListingParams, useFocusEventLister } from '$hooks/actions'
import { useGlobalAppUserService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import { TGlobalAppUserListResponse, TID } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead,
  defaultBackground,
  borderNeutral20
} from '$styles/common.css'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { useCallback, useEffect, useRef } from 'react'

const { colors } = themeVars

const columns: {
  key: Exclude<keyof TGlobalAppUserListResponse['data'][number], 'id'>
  value: string
}[] = [
  { key: 'name', value: 'NAME' },
  { key: 'email', value: 'EMAIL' },
  { key: 'last_login_at', value: 'LAST SIGNED IN' }
]

const GlobalAppUser = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    fetchGlobalAppUserList,
    fetchingGlobalAppUserList,
    globalUsers: { list, meta }
  } = useGlobalAppUserService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useFocusEventLister(() => {
    inputRef.current?.focus()
  })

  useEffect(() => {
    fetchGlobalAppUserList({
      page,
      per_page: perPage,
      q: debouncedSearchValue
    })
  }, [fetchGlobalAppUserList, page, perPage, debouncedSearchValue])

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateSearchValue(e.currentTarget.value)
    },
    [updateSearchValue]
  )

  const onClickRow = useCallback((serviceUserId: TID) => {
    redirect(ROUTE_NAMES.GLOBAL_SERVICE_USER_INDEX, {
      params: { serviceUserId }
    })
  }, [])

  return (
    <>
      <Breadcrumb category={ROUTE_NAMES.GLOBAL_APP_USERS} />

      <article className="flex-1 flex flex-col gap-2 p-2 w-full max-w-[1144px] mx-auto">
        <header className="flex items-end justify-between gap-1 px-4 py-3">
          <article className="flex flex-col">
            <p className="font-semibold" style={{ fontSize: 32 }}>
              Total Application Users - {meta?.total.toLocaleString()}
            </p>
            <p
              className="text-sm font-medium"
              style={{ color: colors.text.disabled }}
            >
              All the users of applications in Carro SSO are here.
            </p>
          </article>

          <article>
            <BaseText
              inputRef={inputRef}
              containerProps={{ className: 'min-w-[250px]' }}
              affix={{
                pre: <Icon namespace="Search" width={16} />,
                post: searchValue ? (
                  <button onClick={() => updateSearchValue('')}>
                    <Icon namespace="Cross" width={18} />
                  </button>
                ) : (
                  <p className="flex gap-x-1 items-center text-[--colors-text-disabled] text-sm">
                    <span>
                      <ModKey />
                    </span>
                    <span>K</span>
                  </p>
                )
              }}
              inputProps={{
                type: 'text',
                value: searchValue,
                onChange: handleOnChange,
                placeholder: 'Search here...'
              }}
            />
          </article>
        </header>

        <main
          className={cn(
            'flex flex-col gap-6 rounded-lg px-12 py-7',
            defaultBackground,
            borderNeutral20
          )}
        >
          <article className={customTableContainer}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    <th className={customTableCell} style={{ width: 0 }}>
                      NO
                    </th>
                    {columns.map((col, key) => (
                      <th key={key} className={customTableCell}>
                        {col.value}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className={tableBody}>
                  {list.map((data, rowKey) => (
                    <tr
                      key={rowKey}
                      className={cn(tableRow, 'cursor-pointer')}
                      onClick={() => onClickRow(data.id)}
                    >
                      <td className={customTableCell}>
                        {(page - 1) * perPage + rowKey + 1}
                      </td>
                      {columns.map((col, colKey) => (
                        <td key={colKey} className={customTableCell}>
                          {(() => {
                            if (col.key === 'last_login_at')
                              return formatDateWithGMT(data.last_login_at)

                            return data[col.key] ?? 'N/A'
                          })()}
                        </td>
                      ))}
                    </tr>
                  ))}

                  {!list.length && !fetchingGlobalAppUserList && (
                    <tr className={tableRow}>
                      <td
                        colSpan={columns.length + 1}
                        style={{ textAlign: 'center' }}
                        className={customTableCell}
                      >
                        NO DATA
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </main>
          </article>

          {fetchingGlobalAppUserList && <Loading />}

          {!!list.length && (
            <footer className="flex px-4 py-2">
              <Pagination meta={meta} pagerProps={pagerProps} />
            </footer>
          )}
        </main>
      </article>
    </>
  )
}

export default GlobalAppUser
