import Modal from '$components/Modal'
import { useModal } from '$components/Modal/hooks/useModal'
import { Button, ButtonProps } from '@genie-fintech/ui/components'
import { History } from 'lucide-react'
import HistoryTable from './HistoryTable'
import { container } from './styles.css'
import { title } from '@genie-fintech/ui/style/typography'
import { TID } from '$services/api'

const buttonVariants: ButtonProps['styleVariants'] = {
  kind: 'neutral',
  type: 'outlined',
  size: 'small'
}

const UnlockHistory = ({ userId }: { userId?: TID }) => {
  const modalActions = useModal({
    closeOnClickOutside: false,
    isFocusMode: true
  })

  return (
    <>
      <article>
        <Button styleVariants={buttonVariants} onClick={modalActions.openModal}>
          Unlock History
          <History size={16} />
        </Button>
      </article>

      <Modal {...modalActions} contentProps={{ className: container }}>
        <article>
          <Button
            styleVariants={buttonVariants}
            onClick={modalActions.closeModal}
          >
            Back to Attack Protection
          </Button>
        </article>

        <p className={title.two}>AUDIT UNLOCK HISTORY</p>

        <HistoryTable userId={userId} />
      </Modal>
    </>
  )
}

export default UnlockHistory
