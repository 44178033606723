import { APIWithPagerMeta } from '$services/api'
import { Pager } from '@genie-fintech/ui/components'
import { usePager } from '@genie-fintech/ui/hooks'
import { themeVars } from '@genie-fintech/ui/style/theme'

const { colors } = themeVars

interface IProps {
  pagerProps: ReturnType<typeof usePager>
  meta?: APIWithPagerMeta['meta']
}

const Pagination = ({ pagerProps, meta }: IProps) => {
  return (
    <article className="flex items-center gap-2 w-full py-0.5">
      {meta && (
        <p className="font-medium" style={{ color: colors.neutral[60] }}>
          {meta.from} - {meta.to} of {meta.total} Results
        </p>
      )}

      <article className="flex-1 flex items-center justify-end">
        <Pager align="right" {...pagerProps} />
      </article>
    </article>
  )
}

export default Pagination
