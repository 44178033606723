import Breadcrumb from '$components/Breadcrumb/v2'
import { redirect, ROUTE_NAMES } from '$router/config'
import { useCallback, useEffect, useState } from 'react'
import { DEFAULT_FORM_VALUES, schema, TFormValues } from '../constants'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  useRedirectPrompt,
  useRedirectProxy,
  useRouteSummary
} from '$contexts/RouteContext/hooks'
import { useAppGroupService, useAppRoleService } from '$hooks/services'
import { TAppRolePayload, TID } from '$services/api'
import { cn } from '$app/utils'
import { defaultBackground, borderNeutral20 } from '$styles/common.css'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { Button, Spinner, Tabs } from '@genie-fintech/ui/components'
import { useCountryStore } from '$hooks/stores'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Asterisk from '$components/Asterisk'
import Select from '$components/Select'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import FooterAction from '$components/FooterAction'
import RolePermissions from '../Detail/RolePermissions'

const { colors } = themeVars

const AppRoleCreate = () => {
  const [step, setStep] = useState(1)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const proxyRedirect = useRedirectProxy()

  const countryOptions = useCountryStore(state => state.countryOptions)

  const { saveRoleAsync, savingRole } = useAppRoleService()

  const { groupOptions, fetchGroupSelectList } = useAppGroupService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues: DEFAULT_FORM_VALUES
  })

  const {
    control,
    handleSubmit,
    trigger,
    formState: { isDirty }
  } = methods

  const { replace } = useFieldArray<TFormValues>({
    name: 'permissions' as never,
    control
  })

  const selectedPermissions = useWatch({ name: 'permissions', control })

  const countryId = useWatch({ name: 'country_id', control })

  useRedirectPrompt({ shouldPrompt: isDirty })

  useEffect(() => {
    if (!appId) return
    fetchGroupSelectList({
      application_id: appId,
      country_id: countryId ?? undefined
    })
  }, [appId, countryId, fetchGroupSelectList])

  const onCancel = useCallback(() => {
    proxyRedirect(ROUTE_NAMES.APP_ROLES, { params })
  }, [proxyRedirect, params])

  const onSuccess = useCallback(
    (roleId: TID) => {
      redirect(ROUTE_NAMES.APP_ROLE_DETAIL, { params: { ...params, roleId } })
    },
    [params]
  )

  const onBack = useCallback(() => {
    setStep(prev => prev - 1)
  }, [])

  const onNext = useCallback(async () => {
    const isValid = await trigger(['name', 'group', 'description'])
    if (isValid) setStep(prev => prev + 1)
  }, [trigger])

  const onSubmit = handleSubmit((formValues: TFormValues) => {
    if (!appId) return

    const { group, ...rest } = formValues

    const payload: TAppRolePayload = {
      ...rest,
      group_id: +group.value
    }

    return saveRoleAsync(appId, payload).then(({ data }) => {
      onSuccess(data.id)
    })
  })

  return (
    <>
      <Breadcrumb
        category={ROUTE_NAMES.APPS}
        data={[
          { name: 'App Details', path: ROUTE_NAMES.APP_ROLES },
          { name: `Add Role` }
        ]}
      />

      <form
        className="flex flex-col max-w-[1056px] mx-auto gap-2"
        onSubmit={onSubmit}
      >
        {step === 1 && (
          <>
            <article
              className={cn(
                'flex flex-col rounded-lg',
                defaultBackground,
                borderNeutral20
              )}
            >
              <header
                className="flex items-center px-12 py-3 border-b"
                style={{ borderColor: colors.neutral[10] }}
              >
                <p
                  className="text-xl font-semibold"
                  style={{ color: colors.text.light }}
                >
                  Add New Role
                </p>
              </header>

              <article className="flex px-12 py-7">
                <article className="flex flex-col gap-6">
                  <article className="flex flex-col gap-1">
                    <p className="font-semibold">COUNTRY/REGION</p>
                    <p
                      className="text-xs"
                      style={{ color: colors.neutral[50] }}
                    >
                      Specify country or region to tailor settings and services
                      accordingly.
                    </p>
                  </article>

                  <Controller
                    name="country_id"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Tabs.Root
                          value={field.value}
                          onValueChange={field.onChange}
                          format={{ type: 'segmented' }}
                        >
                          <Tabs.List styleVariants={{ hAlign: 'left' }}>
                            {countryOptions.map((v, k) => (
                              <Tabs.Trigger key={k} value={`${v.value}`}>
                                {v.label}
                              </Tabs.Trigger>
                            ))}
                          </Tabs.List>
                        </Tabs.Root>
                      )
                    }}
                  />
                </article>
              </article>
            </article>

            <article
              className={cn(
                'flex flex-col rounded-lg px-12 py-7',
                defaultBackground,
                borderNeutral20
              )}
            >
              <article className="grid lg:grid-cols-2 gap-8">
                <article className="flex flex-col gap-1">
                  <p className="font-semibold">ROLE INFO</p>
                  <p className="text-xs" style={{ color: colors.neutral[50] }}>
                    View detailed information about each group to manage roles
                    and permissions effectively.
                  </p>
                </article>

                <article className="flex flex-col gap-5">
                  <BaseText
                    control={control}
                    name="name"
                    label="Role Name"
                    required
                  />

                  <BaseText
                    control={control}
                    name="display_name"
                    label="Display Name"
                    required
                  />

                  <Controller
                    name="group"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <article className="flex flex-col gap-y-1">
                          <label className="text-sm font-medium">
                            Group
                            <Asterisk />
                          </label>
                          <Select
                            {...field}
                            options={groupOptions}
                            error={!!error?.message}
                          />
                          {error?.message && (
                            <p className="text-xs text-[--colors-danger-default]">
                              {error.message}
                            </p>
                          )}
                        </article>
                      )
                    }}
                  />

                  <Textarea
                    name="description"
                    control={control}
                    label="Description"
                  />
                </article>
              </article>
            </article>
          </>
        )}

        {step === 2 && (
          <RolePermissions
            permissions={selectedPermissions}
            onChange={replace}
          />
        )}

        <FooterAction isFullPage>
          <article className="flex w-full max-w-[1056px] gap-2 mx-auto">
            {step === 2 && (
              <Button
                styleVariants={{ kind: 'neutral', type: 'outlined' }}
                disabled={savingRole}
                onClick={onBack}
              >
                Back
              </Button>
            )}
            <article className="flex-1 flex justify-end gap-2">
              <Button
                disabled={savingRole}
                styleVariants={{ type: 'text' }}
                onClick={onCancel}
              >
                Cancel
              </Button>

              {step === 1 && (
                <Button disabled={!isDirty} onClick={onNext}>
                  Next
                </Button>
              )}

              {step === 2 && (
                <Button type="submit" disabled={savingRole}>
                  {savingRole && <Spinner />}
                  Create
                </Button>
              )}
            </article>
          </article>
        </FooterAction>
      </form>
    </>
  )
}

export default AppRoleCreate
