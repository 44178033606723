import { cn } from '$app/utils'
import AppSwitcher from '$components/AppSwitcher'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { onOneOf, ROUTE_NAMES, RouteNames } from '$router/config'
import Link from '$router/Link'
import {
  Folders,
  GalleryHorizontalEnd,
  GitFork,
  Globe2,
  Home,
  Key,
  ListTree,
  LucideIcon,
  Network,
  Settings,
  Settings2,
  ShieldCheck,
  Users2
} from 'lucide-react'
import { AppDetailSidebarStyle } from './styles.css'
import { PERMISSIONS } from '$app/constants'
import { usePermissions } from '$hooks/actions'

const APP_SIDE_MENUS: {
  name: string
  path: RouteNames
  Icon: LucideIcon
  subPath?: RouteNames[]
  permissions: PERMISSIONS[]
}[] = [
  {
    name: 'Home',
    path: ROUTE_NAMES.APP_HOME,
    Icon: Home,
    permissions: []
  },
  {
    name: 'Basic Setting',
    path: ROUTE_NAMES.APP_BASIC_SETTING,
    Icon: Settings2,
    permissions: [PERMISSIONS.VIEW_APPLICATION_BASIC]
  },
  {
    name: 'Advanced Setting',
    path: ROUTE_NAMES.APP_ADVANCED_SETTING,
    Icon: Settings,
    permissions: [PERMISSIONS.VIEW_APPLICATION_ADVANCED]
  },
  {
    name: 'Webhooks',
    path: ROUTE_NAMES.APP_WEBHOOK_SETTING,
    Icon: GitFork,
    permissions: []
  },
  {
    name: 'Launchpad',
    path: ROUTE_NAMES.APP_LAUNCHPAD_SETTING,
    Icon: GalleryHorizontalEnd,
    permissions: [PERMISSIONS.VIEW_APPLICATION_LAUNCHPAD]
  },
  {
    name: 'Group',
    path: ROUTE_NAMES.APP_GROUPS,
    Icon: Folders,
    subPath: [
      ROUTE_NAMES.APP_GROUP_CREATE,
      ROUTE_NAMES.APP_GROUP_DETAIL,
      ROUTE_NAMES.APP_GROUP_EDIT
    ],
    permissions: [PERMISSIONS.VIEW_APPLICATION_GROUP]
  },
  {
    name: 'Users',
    path: ROUTE_NAMES.APP_USERS,
    Icon: Users2,
    subPath: [
      ROUTE_NAMES.APP_USER_CREATE,
      ROUTE_NAMES.APP_USER_DETAIL,
      ROUTE_NAMES.APP_USER_ASSIGN_ROLES
    ],
    permissions: [PERMISSIONS.VIEW_APPLICATION_USER]
  },
  {
    name: 'Roles',
    path: ROUTE_NAMES.APP_ROLES,
    Icon: ListTree,
    subPath: [
      ROUTE_NAMES.APP_ROLE_CREATE,
      ROUTE_NAMES.APP_ROLE_DETAIL,
      ROUTE_NAMES.APP_ROLE_EDIT
    ],
    permissions: [PERMISSIONS.VIEW_APPLICATION_ROLE]
  },
  {
    name: 'Permissions',
    path: ROUTE_NAMES.APP_PERMISSIONS,
    Icon: Network,
    subPath: [
      ROUTE_NAMES.APP_PERMISSION_CREATE,
      ROUTE_NAMES.APP_PERMISSION_DETAIL,
      ROUTE_NAMES.APP_PERMISSION_EDIT
    ],
    permissions: [PERMISSIONS.VIEW_APPLICATION_PERMISSION]
  }
]

const GLOBAL_SETTING_SIDE_MENUS: {
  name: string
  path: RouteNames
  Icon: LucideIcon
  subPath?: RouteNames[]
  permissions: PERMISSIONS[]
}[] = [
  {
    name: 'Countries',
    path: ROUTE_NAMES.COUNTRIES,
    Icon: Globe2,
    subPath: [ROUTE_NAMES.COUNTRY_CREATE, ROUTE_NAMES.COUNTRY_EDIT],
    permissions: [PERMISSIONS.VIEW_COUNTRY]
  },
  {
    name: 'Password Policy',
    path: ROUTE_NAMES.PASSWORD_POLICY,
    Icon: Key,
    subPath: [],
    permissions: []
  },
  {
    name: 'Attack Protection',
    path: ROUTE_NAMES.ATTACK_PROTECTION,
    Icon: ShieldCheck,
    subPath: [],
    permissions: []
  }
]

const Sidebar = () => {
  const { route } = useRouteSummary()

  const { checkPermissions } = usePermissions()

  const SIDE_MENUS = (() => {
    if (
      onOneOf([
        ROUTE_NAMES.PASSWORD_POLICY,
        ROUTE_NAMES.ATTACK_PROTECTION,
        ROUTE_NAMES.COUNTRIES,
        ROUTE_NAMES.COUNTRY_CREATE,
        ROUTE_NAMES.COUNTRY_EDIT
      ])
    ) {
      return GLOBAL_SETTING_SIDE_MENUS
    }
    return APP_SIDE_MENUS
  })()

  const isShowAppSwitcher = APP_SIDE_MENUS.map(v => [
    v.path,
    ...(v.subPath ?? [])
  ])
    .flat()
    .includes(route.name!)

  return (
    <aside className={AppDetailSidebarStyle}>
      {isShowAppSwitcher && <AppSwitcher />}

      <article className="flex flex-1 flex-col py-2">
        {SIDE_MENUS.filter(menu => checkPermissions(menu.permissions)).map(
          (menu, key) => {
            const active = onOneOf(
              menu.subPath ? [menu.path, ...menu.subPath] : [menu.path]
            )
            return (
              <article className="relative px-2" key={key}>
                <Link
                  to={menu.path}
                  options={{ ...route }}
                  data-active={active}
                  className={cn(
                    'grid grid-cols-[auto_1fr] gap-2 p-2 items-center text-sm text-[--colors-neutral-60] rounded',
                    'data-[active=true]:bg-[--colors-area-low] data-[active=true]:font-semibold data-[active=true]:text-[--colors-text-light]',
                    'data-[active=false]:hover:bg-[--colors-area-low] transition-all duration-200'
                  )}
                >
                  {<menu.Icon size={18} />}

                  <p>{menu.name}</p>

                  {active && (
                    <span className="w-[3px] h-full bg-[--colors-primary-default] absolute right-0 rounded-l-lg" />
                  )}
                </Link>
              </article>
            )
          }
        )}
      </article>
    </aside>
  )
}

export default Sidebar
