import Axios, { AxiosError, AxiosResponse } from 'axios'
import { APP_API, APP_API_VERSION, APP_CLIENT_ID } from './environments'
import { APP_STATUS } from '$components/PublishUnpublishButton/types'
import { ACTIVITY_ACTION, PERMISSIONS } from '$app/constants'

export const Api = Axios.create({
  baseURL: `${APP_API}/api`,
  headers: {
    Accept: 'application/json',
    'X-API-TOKEN': APP_CLIENT_ID
  }
})

export const updateAxiosHeaderToken = (token?: string | null) => {
  if (token?.trim().length) {
    Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete Api.defaults.headers.common['Authorization']
  }
}

/** ********************************************************************
 * Resolvers
 */
export const successResolver = <T extends AxiosResponse>({
  data
}: T): T['data'] => data

export const errorMessageResolver = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  defaultMessage = 'Something went wrong!'
): string => {
  return (
    error?.response?.data?.message || error?.response?.message || defaultMessage
  )
}

export const errorStatusResolver = (error: unknown) => {
  return (error as Required<AxiosError>).response.status
}

/** ********************************************************************
 * Common
 */
export type TID = string | number

export type APIWithSearch<T = object> = { q?: string } & T

export type APIWithPager<T = object> = {
  per_page: number
  page: number
} & T

export type APIWithPagerMeta<T = object> = T & {
  meta: {
    total: number
    per_page: number
    current_page: number
    last_page: number
    from: number
    to: number
  }
}

export type TAppLogo = {
  key?: string
  url?: string
} | null

/** ********************************************************************
 * Services
 */

/** ********************************************************************
 * FILE UPLOAD
 */
export const configUpload = async () => {
  return Api.post('/dashboard/vapor/signed-storage-url').then(successResolver)
}

/** ********************************************************************
 * LOGIN
 */
export type TLoginPayload = {
  email: string
  password: string
}

export type TLoginResponse = {
  token: string
  data: {
    name: string
  }
}

export const postLogin = async (payload: TLoginPayload) => {
  return Api.post<TLoginResponse>('/dashboard/login', payload).then(
    successResolver
  )
}

/** ********************************************************************
 * LOGOUT
 */
export const postLogout = async () => {
  return Api.post('/dashboard/logout').then(successResolver)
}

/** ********************************************************************
 * Profile
 */
export type TUserProfile = {
  id: string
  name: string
  email: string
  two_factor_enabled: boolean
  role: string
  has_global_access: boolean
  permissions: PERMISSIONS[]
}

export const getProfile = async () => {
  return Api.get<AxiosResponse<TUserProfile>>(
    `/${APP_API_VERSION}/profile/info`
  ).then(successResolver)
}

/** ********************************************************************
 * COUNTRY
 */
export type TCountry = {
  id: number
  name: string
  country_code: string
  phone_code: string
  timezone: string
  currency_name: string
  currency_code: string
  currency_symbol: string
}

export const getCountries = async () => {
  return Api.get<AxiosResponse<TCountry[]>>(
    `/${APP_API_VERSION}/countries`
  ).then(successResolver)
}

export type TCountryPayload = Omit<TCountry, 'id'>

export const postCountry = async (payload: TCountryPayload) => {
  return Api.post(`${APP_API_VERSION}/countries`, payload).then(successResolver)
}

export const putCountry = async (id: TID, payload: TCountryPayload) => {
  return Api.put(`${APP_API_VERSION}/countries/${id}`, payload).then(
    successResolver
  )
}
/** ********************************************************************
 * SCOPE
 */
export type TAppScope = {
  id: number
  value: string
}

export const getAppScope = async () => {
  return Api.get<AxiosResponse<TAppScope[]>>(`/${APP_API_VERSION}/scopes`).then(
    successResolver
  )
}

/** ********************************************************************
 * IMPORT
 */
export type TImportPayload = {
  appId: TID
  type: 'role' | 'permission' | 'user'
  file: string
}

export const importDataApi = async ({
  appId,
  type,
  ...payload
}: TImportPayload) => {
  return Api.post(`/${APP_API_VERSION}/${appId}/import/${type}s`, payload).then(
    successResolver
  )
}

/** ********************************************************************
 * Password Policy Settings
 */

export const getPasswordPolicySetting = async () => {
  return Api.get<
    AxiosResponse<{ password_rules: { description: string; regex: string }[] }>
  >(`/settings`).then(successResolver)
}

/** ********************************************************************
 * UPDATE PASSWORD
 */

export type TUpdatePasswordPayload = {
  isAdmin: boolean
  userId: TID
  password: string
  should_reset_password: boolean
}

export const postUpdatePassword = async ({
  isAdmin,
  userId,
  ...payload
}: TUpdatePasswordPayload) => {
  const prefix = isAdmin ? 'admin-users' : 'users'
  return Api.post(
    `/${APP_API_VERSION}/${prefix}/${userId}/update-password`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * UPDATE USER STATUS
 */

export type TUserStatus = 'active' | 'inactive'

export type TUpdateUserStatusPayload = {
  isAdmin: boolean
  userId: TID
  status: TUserStatus
}

export const postUpdateUserStatus = async ({
  isAdmin,
  userId,
  ...payload
}: TUpdateUserStatusPayload) => {
  const prefix = isAdmin ? 'admin-users' : 'users'
  return Api.post(
    `/${APP_API_VERSION}/${prefix}/${userId}/change-status`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATIONS
 */

// APP LIST API
export type TAppListRequest = APIWithPager<APIWithSearch>

export type TAppListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    client_id: string
    environment?: {
      key: number
      value: string
    } | null
    logo: TAppLogo
    brand: string
    group_count: number
    role_count: number
    user_count: number
    is_published: boolean
  }[]
}>

export const getAppList = async (params: TAppListRequest) => {
  return Api.get<TAppListResponse>(`/${APP_API_VERSION}/applications`, {
    params
  }).then(successResolver)
}

// APP SELECT LIST API
export type TAppSelectListRequest = APIWithSearch<{ application_id?: TID }> &
  Pick<APIWithPager, 'per_page'>

export type TAppSelectListResponse = {
  data: {
    id: number
    name: string
    logo: TAppLogo
    brand: string
    is_published: boolean
    environment: {
      key: number
      value: string
    }
  }[]
}

export const getAppSelectList = async (params: TAppSelectListRequest) => {
  return Api.get<TAppSelectListResponse>(
    `/${APP_API_VERSION}/applications-select`,
    {
      params
    }
  ).then(successResolver)
}

// PUBLISH/UNPUBLISH APP API
export const getPublishApp = async ({
  application_id
}: {
  application_id: TID
}) => {
  return Api.get(
    `/${APP_API_VERSION}/applications/${application_id}/publish`
  ).then(successResolver)
}

export const getUnPublishApp = async ({
  application_id
}: {
  application_id: TID
}) => {
  return Api.get(
    `/${APP_API_VERSION}/applications/${application_id}/unpublish`
  ).then(successResolver)
}

export const getPublishUnpublishApp = async ({
  appId,
  status
}: {
  appId: TID
  status: APP_STATUS
}) => {
  return Api.get(`/${APP_API_VERSION}/applications/${appId}/${status}`).then(
    successResolver
  )
}

// CREATE APP API
export type TAppCreatePayload = {
  name: string
  environment: number
  logo: TAppLogo
  brand: string
}

export const postApp = async (payload: TAppCreatePayload) => {
  return Api.post<AxiosResponse<{ id: number; client_id: string }>>(
    `/${APP_API_VERSION}/applications`,
    payload
  ).then(successResolver)
}

// DELETE APP API
export type TDeleteAppPayload = {
  appId: TID
}

export const deleteApp = async ({ appId }: TDeleteAppPayload) => {
  return Api.delete(`/${APP_API_VERSION}/applications/${appId}`).then(
    successResolver
  )
}

/** ********************************************************************
 * APPLICATION HOME
 */
// GET BASIC SETTING API
export type TAppHomeParams = {
  application_id: TID
}

export type TAppHome = {
  user_count: number
  role_count: number
  group_count: number
  today_active_user_count: number
  recent_active_user_counts: number[]
  active_users: {
    date: string
    count: number
  }[]
}

export const getAppHome = async ({
  application_id,
  ...params
}: TAppHomeParams) => {
  return Api.get<AxiosResponse<TAppHome>>(
    `/${APP_API_VERSION}/applications/${application_id}/home`,
    { params }
  ).then(successResolver)
}

export const getVisitorsByLocation = async ({
  application_id
}: {
  application_id: TID
}) => {
  return Api.get<
    AxiosResponse<{ display_name: string; name: string; count: number }[]>
  >(
    `/${APP_API_VERSION}/applications/${application_id}/visitor-count-by-locations`
  ).then(successResolver)
}

export type FilterTypeVisitors = 'device' | 'os' | 'browser'

export const getVisitorsByDevice = async ({
  application_id,
  ...params
}: {
  application_id: TID
  group_by: FilterTypeVisitors
}) => {
  return Api.get<
    AxiosResponse<{ display_name: string; name: string; count: number }[]>
  >(
    `/${APP_API_VERSION}/applications/${application_id}/visitor-count-by-devices`,
    { params }
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION BASIC SETTING
 */

// GET BASIC SETTING API
export type TAppBasicSettingParams = {
  application_id: TID
}

export type TAppBasicSetting = {
  id: number
  name: string
  client_id: string
  description: string
  logo: TAppLogo
  brand: string
  scope: {
    id: number
    value: string
  } | null
  api_tokens: {
    api_token: string
    encryption_key: string
  }[]
  login_behavior: string
  is_published: boolean
  environment: {
    key: number
    value: string
  }
}

export const getAppBasicSetting = async (params: TAppBasicSettingParams) => {
  return Api.get<AxiosResponse<TAppBasicSetting>>(
    `/${APP_API_VERSION}/applications/${params.application_id}/basic-info`
  ).then(successResolver)
}

// PUT BASIC SETTING API
export type TAppBasicSettingPayload = {
  name: string
  description: string
  scope_id: number
  login_behavior: string
  logo: TAppLogo
  brand: string
  environment: number
}

export const putAppBasicSetting = async (
  appId: TID,
  payload: TAppBasicSettingPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/basic-info`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION ADVANCED SETTING
 */

// GET ADVANCED SETTING API
export type TAppAdvancedSettingParams = {
  application_id: TID
}

export type TAppAdvancedSetting = {
  acl_type: string
  sso_enabled: boolean
  allowed_multi_device_login: boolean
  callbacks: {
    callback_url: string
  }[]
  allowed_origins: {
    resource: string
  }[]
}

export const getAppAdvancedSetting = async (
  params: TAppAdvancedSettingParams
) => {
  return Api.get<AxiosResponse<TAppAdvancedSetting>>(
    `/${APP_API_VERSION}/applications/${params.application_id}/advanced-info`
  ).then(successResolver)
}

// PUT ADVANCED SETTING API
export type TAppAdvancedSettingPayload = TAppAdvancedSetting

export const putAppAdvancedSetting = async (
  appId: TID,
  payload: TAppAdvancedSettingPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/advanced-info`,
    payload
  ).then(successResolver)
}
/** ********************************************************************
 * APPLICATION LAUNCHPAD SETTING
 */

// GET LAUNCHPAD API
export type TAppLaunchpadParams = {
  application_id: TID
}

export type TAppLaunchpad = {
  name: string
  url: string
  description: string
}

export const getAppLaunchpads = async (params: TAppAdvancedSettingParams) => {
  return Api.get<AxiosResponse<{ launchpads: TAppLaunchpad[] }>>(
    `/${APP_API_VERSION}/applications/${params.application_id}/launchpads`
  ).then(successResolver)
}

// PUT LAUNCHPAD API
export const putAppLaunchpads = async (
  appId: TID,
  launchpads: TAppLaunchpad[]
) => {
  return Api.put(`/${APP_API_VERSION}/applications/${appId}/launchpads`, {
    launchpads
  }).then(successResolver)
}

/** ********************************************************************
 * APPLICATION GROUP
 */

// APP GROUP LIST API
export type TAppGroupListRequest = APIWithPager<
  APIWithSearch<{ application_id: TID }>
>

export type TAppGroupListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    description: string
    role_count: number
    user_count: number
    created_at: string
    country: string
  }[]
}>

// GET GROUP LIST API
export const getAppGroupList = async ({
  application_id,
  ...params
}: TAppGroupListRequest) => {
  return Api.get<TAppGroupListResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/groups`,
    {
      params
    }
  ).then(successResolver)
}

// GET GROUP SELECT LIST API
export type TAppGroupSelectListRequest = APIWithSearch<{
  application_id: TID
  country_id?: TID
}>

export const getAppGroupSelectList = async ({
  application_id,
  ...params
}: TAppGroupSelectListRequest) => {
  return Api.get<AxiosResponse<{ id: number; name: string }[]>>(
    `/${APP_API_VERSION}/applications/${application_id}/groups-select`,
    {
      params
    }
  ).then(successResolver)
}

// GET GROUP DETAIL API
export type TAppGroupDetailRequest = {
  application_id: TID
  group_id: TID
}

export type TAppGroupDetail = {
  id: number
  name: string
  phone_no: string
  phone_code: string
  address: string
  description: string
  country: TCountry
}

export const getAppGroupDetail = async ({
  application_id,
  group_id
}: TAppGroupDetailRequest) => {
  return Api.get<AxiosResponse<TAppGroupDetail>>(
    `/${APP_API_VERSION}/applications/${application_id}/groups/${group_id}`
  ).then(successResolver)
}

export type TAppGroupPayload = {
  name: string
  phone_no?: string
  phone_code?: string
  address?: string
  description?: string
  country_id: number
}

// CREATE GROUP API
export const postAppGroup = async (appId: TID, payload: TAppGroupPayload) => {
  return Api.post<AxiosResponse<{ id: TID }>>(
    `/${APP_API_VERSION}/applications/${appId}/groups`,
    payload
  ).then(successResolver)
}

// UPDATE GROUP API
export const putAppGroup = async (
  appId: TID,
  groupId: TID,
  payload: TAppGroupPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/groups/${groupId}`,
    payload
  ).then(successResolver)
}

// GET GROUP USER API
export type TAppGroupUserRequest = APIWithPager<
  APIWithSearch<{
    application_id: TID
    group_id: TID
  }>
>

export type TAppGroupUserResponse = APIWithPagerMeta<{
  data: {
    id: string
    name: string
    email: string
    status: 'active' | 'inactive'
    role_count: number
  }[]
}>

export const getAppGroupUser = async ({
  application_id,
  group_id,
  ...params
}: TAppGroupUserRequest) => {
  return Api.get<TAppGroupUserResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/groups/${group_id}/users`,
    {
      params
    }
  ).then(successResolver)
}

// GET GROUP ROLE API
export type TAppGroupRoleRequest = APIWithPager<
  APIWithSearch<{
    application_id: TID
    group_id: TID
  }>
>

export type TAppGroupRoleResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    display_name: string
    user_count: number
    permission_count: number
    description: string
  }[]
}>

export const getAppGroupRole = async ({
  application_id,
  group_id,
  ...params
}: TAppGroupRoleRequest) => {
  return Api.get<TAppGroupRoleResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/groups/${group_id}/roles`,
    {
      params
    }
  ).then(successResolver)
}

// DELETE GROUP API
export type TDeleteGroupPayload = {
  appId: TID
  groupId: TID
}

export const deleteGroup = async ({ appId, groupId }: TDeleteGroupPayload) => {
  return Api.delete(
    `/${APP_API_VERSION}/applications/${appId}/groups/${groupId}`
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION ROLES
 */

// APP ROLE LIST API
export type TAppRoleListRequest = APIWithPager<
  APIWithSearch<{ application_id: TID; country_id?: TID }>
>

export type TAppRoleListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    display_name: string
    user_count: number
    permission_count: number
    group: {
      id: number
      name: string
      country_id: number
    }
    description: string
  }[]
}>

// GET ROLE LIST API
export const getAppRoleList = async ({
  application_id,
  ...params
}: TAppRoleListRequest) => {
  return Api.get<TAppRoleListResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/roles`,
    {
      params
    }
  ).then(successResolver)
}

// GET ROLE DETAIL API
export type TAppRoleDetailRequest = {
  application_id: TID
  role_id: TID
}

export type TAppRoleDetail = {
  id: number
  name: string
  display_name: string
  group: {
    id: number
    name: string
    country_id: number
  }
  description: string
  permissions: number[]
}

export const getAppRoleDetail = async ({
  application_id,
  role_id
}: TAppRoleDetailRequest) => {
  return Api.get<AxiosResponse<TAppRoleDetail>>(
    `/${APP_API_VERSION}/applications/${application_id}/roles/${role_id}`
  ).then(successResolver)
}

export type TAppRolePayload = {
  name: string
  display_name: string
  group_id: number
  description: string
  permissions: TID[]
}

// CREATE ROLE API
export const postAppRole = async (appId: TID, payload: TAppRolePayload) => {
  return Api.post<AxiosResponse<{ id: TID }>>(
    `/${APP_API_VERSION}/applications/${appId}/roles`,
    payload
  ).then(successResolver)
}

// DELETE ROLE API
export type TDeleteRolePayload = {
  appId: TID
  roleId: TID
}

export const deleteRole = async ({ appId, roleId }: TDeleteRolePayload) => {
  return Api.delete(
    `/${APP_API_VERSION}/applications/${appId}/roles/${roleId}`
  ).then(successResolver)
}

// UPDATE ROLE API
export const putAppRole = async (
  appId: TID,
  roleId: TID,
  payload: TAppRolePayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/roles/${roleId}`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION PERMISSIONS
 */

// APP PERMISSION LIST API
export type TAppPermissionListRequest = APIWithPager<
  APIWithSearch<{ application_id: TID }>
>

export type TAppPermissionListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    module_name: string
    created_at: string
  }[]
}>

// GET PERMISSION LIST API
export const getAppPermissionList = async ({
  application_id,
  ...params
}: TAppPermissionListRequest) => {
  return Api.get<TAppPermissionListResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/permissions`,
    {
      params
    }
  ).then(successResolver)
}

// GET PERMISSION DETAIL API
export type TAppPermissionDetailRequest = {
  application_id: TID
  permission_id: TID
}

export type TAppPermissionDetail = {
  id: number
  name: string
  module_name: string
  description: string
  roles: {
    id: number
    name: string
    group: {
      id: number
      name: string
    }
    country: {
      id: number
      name: string
      country_code: string
    }
  }[]
}

export const getAppPermissionDetail = async ({
  application_id,
  permission_id
}: TAppPermissionDetailRequest) => {
  return Api.get<AxiosResponse<TAppPermissionDetail>>(
    `/${APP_API_VERSION}/applications/${application_id}/permissions/${permission_id}`
  ).then(successResolver)
}

export type TAppPermissionPayload = {
  name: string
  module_name: string
  description: string
  roles: number[]
}

// CREATE PERMISSION API
export const postAppPermission = async (
  appId: TID,
  payload: TAppPermissionPayload
) => {
  return Api.post<AxiosResponse<{ id: number }>>(
    `/${APP_API_VERSION}/applications/${appId}/permissions`,
    payload
  ).then(successResolver)
}

// DELETE PERMISSION API
export type TDeletePermissionPayload = {
  appId: TID
  permissionId: TID
}

export const deletePermission = async ({
  appId,
  permissionId
}: TDeletePermissionPayload) => {
  return Api.delete(
    `/${APP_API_VERSION}/applications/${appId}/permissions/${permissionId}`
  ).then(successResolver)
}

// UPDATE PERMISSION API
export const putAppPermission = async (
  appId: TID,
  permissionId: TID,
  payload: TAppPermissionPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/permissions/${permissionId}`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION USERS
 */

// APP USER LIST API
export type TAppUserListRequest = APIWithPager<
  APIWithSearch<{ application_id: TID }>
>

export type TAppUserListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    email: string
    role_count: number
    group_count: number
    status: 'active' | 'inactive'
  }[]
}>

// GET USER LIST API
export const getAppUserList = async ({
  application_id,
  ...params
}: TAppUserListRequest) => {
  return Api.get<TAppUserListResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/users`,
    {
      params
    }
  ).then(successResolver)
}

// GET QUERY USER BY EMAIL
export const getQueryUserByEmail = async ({
  application_id,
  ...params
}: {
  application_id: TID
  email: string
}) => {
  return Api.get<AxiosResponse<{ id?: string; status: string }>>(
    `/${APP_API_VERSION}/applications/${application_id}/query-users`,
    {
      params
    }
  ).then(successResolver)
}

// GET USER DETAIL API
export type TAppUserDetailRequest = {
  application_id: TID
  user_id: TID
}

export type TAppUserDetail = {
  id: string
  name: string
  email: string
  date_of_birth: string
  status: 'active' | 'inactive'
  gender: string
  address: string
  phone_no: string
  phone_code: string
  roles: {
    id: number
    name: string
    country: {
      id: number
      name: string
      country_code: string
    }
    group: {
      id: number
      name: string
    }
  }[]
  password_reset_link_expired_in: number | null
  user_type: SERVICE_USER_TYPE
}

export const getAppUserDetail = async ({
  application_id,
  user_id
}: TAppUserDetailRequest) => {
  return Api.get<AxiosResponse<TAppUserDetail>>(
    `/${APP_API_VERSION}/applications/${application_id}/users/${user_id}`
  ).then(successResolver)
}

// DELETE APP USER
export type TTerminateAppUserPayload = {
  appId: TID
  userId: TID
}

export const deleteAppUser = async ({
  appId,
  userId
}: TTerminateAppUserPayload) => {
  return Api.delete(
    `/${APP_API_VERSION}/applications/${appId}/users/${userId}`
  ).then(successResolver)
}

// CREATE USER API
export type TAppUserPayload = {
  name: string
  email: string
  date_of_birth: string
  gender: string
  address: string
  phone_no: string
  phone_code: string
  status?: string
  roles: number[]
}

export const postAppUser = async (
  appId: TID,
  payload: TAppUserPayload & {
    password: string
    should_reset_password: boolean
    is_internal: boolean
  }
) => {
  return Api.post<AxiosResponse<{ id: TID }>>(
    `/${APP_API_VERSION}/applications/${appId}/users`,
    payload
  ).then(successResolver)
}

// UPDATE USER API
export const putAppUser = async (
  appId: TID,
  userId: TID,
  payload: TAppUserPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/users/${userId}`,
    payload
  ).then(successResolver)
}

// ATTACH ROLES TO USER
export const postAttachRoleSToUsers = async (
  appId: TID,
  userId: TID,
  roles: number[]
) => {
  return Api.post(
    `${APP_API_VERSION}/applications/${appId}/users/${userId}/add-roles`,
    { roles }
  ).then(successResolver)
}

// POST RESET PASSWORD LINK API
export const postResetPasswordLink = async ({
  appId,
  userId
}: {
  appId: TID
  userId: TID
}) => {
  return Api.post<AxiosResponse<{ url: string }>>(
    `/${APP_API_VERSION}/applications/${appId}/users/${userId}/reset-password`
  ).then(successResolver)
}

// DELETE RESET PASSWORD LINK API
export const deleteResetPasswordLink = async ({
  appId,
  userId
}: {
  appId: TID
  userId: TID
}) => {
  return Api.delete(
    `/${APP_API_VERSION}/applications/${appId}/users/${userId}/reset-password`
  ).then(successResolver)
}

/** ********************************************************************
 * PASSWORD POCLICY
 */

export type TPasswordPolicy = {
  id: number
  name: string
  is_enabled: boolean
  min_length: number | null
}

export const getPasswordPolicy = async () => {
  return Api.get<AxiosResponse<TPasswordPolicy[]>>(
    `/${APP_API_VERSION}/password-policies`
  ).then(successResolver)
}

export type TPasswordPolicyPayload = Omit<TPasswordPolicy, 'id'>

export const putPasswordPolicy = async (payload: {
  policies: TPasswordPolicyPayload[]
}) => {
  return Api.put(`/${APP_API_VERSION}/password-policies`, payload).then(
    successResolver
  )
}

/** ********************************************************************
 * GLOBAL APP USERS
 */

// GET GLOBAL USER LIST API

export type TGlobalAppUserListRequest = APIWithPager<APIWithSearch>

export type TGlobalAppUserListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    email: string
    last_login_at: string
  }[]
}>

export const getGlobalAppUserList = async (
  params: TGlobalAppUserListRequest
) => {
  return Api.get<TGlobalAppUserListResponse>(`/${APP_API_VERSION}/users`, {
    params
  }).then(successResolver)
}

export type TGlobalAppUser = {
  id: string
  name: string
  email: string
  status: 'active' | 'inactive'
  phone_code: string
  phone_no: string
  address: string
  gender: string
  date_of_birth: string
  created_at: string
  last_login_at: string
  applications: {
    id: number
    name: string
    logo: TAppLogo
    brand: string
  }[]
  user_type: SERVICE_USER_TYPE
  timezone: string
}

// GET GLOBAL APP USER API
export const getGlobalAppUserDetail = async (userId: TID) => {
  return Api.get<AxiosResponse<TGlobalAppUser>>(
    `/${APP_API_VERSION}/users/${userId}`
  ).then(successResolver)
}

export type TGlobalUserPayload = {
  name: string
  email: string
  phone_code: string
  phone_no: string
  address: string
  gender: string
  date_of_birth: string
}

// PUT GLOBAL APP USER API
export const putGlobalAppUserDetail = async (
  userId: TID,
  payload: TGlobalUserPayload
) => {
  return Api.put(`/${APP_API_VERSION}/users/${userId}`, payload).then(
    successResolver
  )
}

/** ********************************************************************
 * GLOBAL DASHBOARD USERS
 */

// GLOBAL DASHBOARD USER LIST API
export type TGlobalDashboardUserListRequest = APIWithPager<APIWithSearch>

export type TGlobalDashboardUserListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    email: string
    type: string
    role: string
  }[]
}>

export const getGlobalDashboardUserList = async (
  params: TGlobalDashboardUserListRequest
) => {
  return Api.get<TGlobalDashboardUserListResponse>(
    `/${APP_API_VERSION}/admin-users`,
    {
      params
    }
  ).then(successResolver)
}

// GET GLOBAL DASHBOARD USER API
export const getGlobalDashboardUserDetail = async (userId: TID) => {
  return Api.get<
    AxiosResponse<TGlobalAppUser & { type: string; role: string }>
  >(`/${APP_API_VERSION}/admin-users/${userId}`).then(successResolver)
}

// GET USER TYPE SELECT API
export const getUserTypeSelect = async () => {
  return Api.get<AxiosResponse<{ name: string; display_name: string }[]>>(
    `/${APP_API_VERSION}/admin-users/types`
  ).then(successResolver)
}

// GET USER TYPE SELECT API
export const getUserRoleSelect = async () => {
  return Api.get<AxiosResponse<{ name: string; display_name: string }[]>>(
    `/${APP_API_VERSION}/admin-users/roles`
  ).then(successResolver)
}

export type TGlobalDashboardUserPayload = {
  type: string
  role: string
  name: string
  email: string
  phone_code: string
  phone_no: string
}

// POST GLOBAL DASHBOARD USER API
export const postGlobalDashboardUserDetail = async (
  payload: TGlobalDashboardUserPayload & {
    password: string
    applications: number[]
  }
) => {
  return Api.post<AxiosResponse<{ id: TID }>>(
    `/${APP_API_VERSION}/admin-users`,
    payload
  ).then(successResolver)
}

// PUT GLOBAL DASHBOARD USER API
export const putGlobalDashboardUserDetail = async (
  userId: TID,
  payload: TGlobalDashboardUserPayload
) => {
  return Api.put(`/${APP_API_VERSION}/admin-users/${userId}`, payload).then(
    successResolver
  )
}

// GET APPLICATIONS TO ASSIGN
export const getAppsToAssign = async (
  params: APIWithPager<APIWithSearch<{ app_env: TID }>>
) => {
  return Api.get<APIWithPagerMeta<TAppSelectListResponse>>(
    `/${APP_API_VERSION}/admin-users-applications`,
    { params }
  ).then(successResolver)
}

// POST APPLICATIONS TO ASSIGN
export const postAppsToAssign = async (
  userId: TID,
  payload: { applications: number[] }
) => {
  return Api.post(
    `/${APP_API_VERSION}/admin-users/${userId}/assign-applications`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * GLOBAL SERVICE USERS
 */

export enum SERVICE_USER_TYPE {
  INTERNAL = 'internal',
  EXTERNAL = 'external'
}

export type TGlobalServiceUserInfo = {
  id: string
  name: string
  email: string
  status: 'active' | 'inactive'
  phone_code: string
  phone_no: string
  address: string
  gender: string
  date_of_birth: string
  created_at: string
  last_login_at: string
  location?: string
  ip_address?: string
  password_updated_at?: string
  user_type: SERVICE_USER_TYPE
  timezone: string
  type?: string
  role?: string
  applications?: {
    id: number
    name: string
    logo: TAppLogo
    brand: string
  }[]
}

export const getServiceUserInfo = async ({ userId }: { userId: TID }) => {
  return Api.get<AxiosResponse<TGlobalServiceUserInfo>>(
    `/${APP_API_VERSION}/users/${userId}/info`
  ).then(successResolver)
}

export const getServiceUserAccessedApps = async ({
  userId
}: {
  userId: TID
}) => {
  return Api.get<
    AxiosResponse<{ applications: TAppSelectListResponse['data'] }>
  >(`/${APP_API_VERSION}/users/${userId}/accessed-applications`).then(
    successResolver
  )
}

export type TServiceUserPayload = {
  name: string
  address: string
  gender: string
  date_of_birth: string
}

export const putServiceUser = async (
  userId: TID,
  payload: TServiceUserPayload
) => {
  return Api.put(`/${APP_API_VERSION}/users/${userId}`, payload).then(
    successResolver
  )
}

export type TGlobalServiceUserPasswordSetting = {
  access_token_expires_in: number | null
  password_history_depth: number
  should_reset_password: boolean
  password_expiration_days: number
  password_reset_link_expired_in: number | null
}

export const getServiceUserPasswordSetting = async ({
  userId
}: {
  userId: TID
}) => {
  return Api.get<AxiosResponse<TGlobalServiceUserPasswordSetting>>(
    `/${APP_API_VERSION}/users/${userId}/password-settings`
  ).then(successResolver)
}

export const postServiceUserPasswordSetting = async (
  userId: TID,
  payload: Omit<
    TGlobalServiceUserPasswordSetting,
    'password_reset_link_expired_in' | 'should_reset_password'
  >
) => {
  return Api.post(
    `/${APP_API_VERSION}/users/${userId}/password-settings`,
    payload
  ).then(successResolver)
}

export const postServiceUserForceLogout = async (userId: TID) => {
  return Api.post(`/${APP_API_VERSION}/users/${userId}/force-logout`).then(
    successResolver
  )
}

export const postServiceUserForceUpdate = async (
  userId: TID,
  payload: Pick<TGlobalServiceUserPasswordSetting, 'should_reset_password'>
) => {
  return Api.post(
    `/${APP_API_VERSION}/users/${userId}/force-reset-password`,
    payload
  ).then(successResolver)
}

export const postServiceUserGeneratePasswordLink = async (userId: TID) => {
  return Api.post<AxiosResponse<{ url: string }>>(
    `/${APP_API_VERSION}/users/${userId}/password-reset-link`
  ).then(successResolver)
}

export const deleteServiceUserGeneratePasswordLink = async (userId: TID) => {
  return Api.delete(
    `/${APP_API_VERSION}/users/${userId}/password-reset-link`
  ).then(successResolver)
}

export const getServiceUserMFASetting = async ({ userId }: { userId: TID }) => {
  return Api.get<
    AxiosResponse<{ id: TID; mfa_enabled: boolean; mfa_enforced: boolean }>
  >(`/${APP_API_VERSION}/users/${userId}/mfa-settings`).then(successResolver)
}

export const postServiceUserEnforceMFASetting = async (
  userId: TID,
  payload: { enforce_mfa: boolean }
) => {
  return Api.post(
    `/${APP_API_VERSION}/users/${userId}/enforce-mfa`,
    payload
  ).then(successResolver)
}

export type TData_OTP = {
  code: string
  reference_code: string
  expires_in: number
  is_verified: boolean
  message: string
}

export const getCurrentOTP = async ({ userId }: { userId: TID }) => {
  return Api.get<AxiosResponse<TData_OTP>>(
    `/${APP_API_VERSION}/users/${userId}/mfa-code`
  ).then(successResolver)
}

export type TPasswordSetting = {
  access_token_expires_in: number | null
  password_expiration_days: number
  password_history_depth: number
  mfa_enabled: boolean
}

export const getCurrentPasswordSettingValues = async ({
  userId
}: {
  userId: TID
}) => {
  return Api.get<AxiosResponse<TPasswordSetting>>(
    `/${APP_API_VERSION}/setting/users/${userId}`
  ).then(successResolver)
}

export const getDefaultPasswordSettingValues = async () => {
  return Api.get<
    AxiosResponse<(TPasswordSetting & { type: SERVICE_USER_TYPE })[]>
  >(`/${APP_API_VERSION}/setting/users`).then(successResolver)
}

export type TServiceUserTypePayload = {
  is_internal: boolean
  modify_login_rules: boolean
}

export const putServiceUserType = async (
  userId: TID,
  payload: TServiceUserTypePayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/users/${userId}/change-internal-status`,
    payload
  ).then(successResolver)
}

export const getTimezones = async () => {
  return Api.get<AxiosResponse<{ key: string; value: string }[]>>(
    `/${APP_API_VERSION}/timezones`
  ).then(successResolver)
}

export const putTimezone = async (userId: TID, timezone: string) => {
  return Api.put(`/${APP_API_VERSION}/users/${userId}/timezone`, {
    timezone
  }).then(successResolver)
}

export const putServiceUserEmail = async ({
  userId,
  ...payload
}: {
  userId: TID
  password: string
  email: string
}) => {
  return Api.put(`/${APP_API_VERSION}/users/${userId}/email`, payload).then(
    successResolver
  )
}

export const putServiceUserPhone = async ({
  userId,
  ...payload
}: {
  userId: TID
  password: string
  phone_no: string
  phone_code: string
}) => {
  return Api.put(`/${APP_API_VERSION}/users/${userId}/phone`, payload).then(
    successResolver
  )
}

/** ********************************************************************
 * WEBHOOK
 */
export type WEBHOOK_EVENT = {
  id: string
  webhook_event_category_id: string
  event_type: string
  name: string
  description: string
  created_at: string
  updated_at: string
}

export const getWebhookEvents = async () => {
  return Api.get<AxiosResponse<WEBHOOK_EVENT[]>>(
    `/${APP_API_VERSION}/webhook-events`
  ).then(successResolver)
}

export type APP_WEBHOOKS_REQUEST = APIWithPager<{ appId: TID }>

export type APP_WEBHOOK = {
  id: string
  url: string
  is_enabled: boolean
  webhook_events: WEBHOOK_EVENT[]
}

export const getWebhooksByAppId = async ({
  appId,
  ...params
}: APP_WEBHOOKS_REQUEST) => {
  return Api.get<APIWithPagerMeta<{ data: APP_WEBHOOK[] }>>(
    `/${APP_API_VERSION}/applications/${appId}/webhook-endpoints`,
    { params }
  ).then(successResolver)
}

export type WEBHOOK_PAYLOAD = Partial<Omit<APP_WEBHOOK, 'is_enabled'>> & {
  is_enabled?: boolean
}

export const postWebhook = async (appId: TID, payload: WEBHOOK_PAYLOAD) => {
  return Api.post(
    `/${APP_API_VERSION}/applications/${appId}/webhook-endpoints`,
    payload
  ).then(successResolver)
}

export const putWebhook = async (
  appId: TID,
  { id, ...payload }: WEBHOOK_PAYLOAD
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/webhook-endpoints/${id}`,
    payload
  ).then(successResolver)
}

export const deleteWebhook = async (appId: TID, webhookId: TID) => {
  return Api.delete(
    `/${APP_API_VERSION}/applications/${appId}/webhook-endpoints/${webhookId}`
  ).then(successResolver)
}

export const postSentTestWebhook = async (appId: TID, webhookId: TID) => {
  return Api.post(
    `/${APP_API_VERSION}/applications/${appId}/webhook-endpoints/${webhookId}/send-test`
  ).then(successResolver)
}

/** ********************************************************************
 * Public Key
 */
export type PublicKey = {
  action: string
  updated_at: string
  generated_by: string
}

export type TGeneratePublicKeyPayload = {
  client_id: string
  force: boolean
}

export type PublicKeyResponse = AxiosResponse<{ public_key: PublicKey | null }>

export const getAppPublicKey = async (appId: TID) => {
  return Api.get<PublicKeyResponse>(
    `/${APP_API_VERSION}/applications/${appId}/keys-info`
  ).then(successResolver)
}

export const postGeneratePublicKey = async (
  params: TGeneratePublicKeyPayload
) => {
  return Api.post<PublicKeyResponse>(
    `/${APP_API_VERSION}/generate-public-key`,
    params
  ).then(successResolver)
}

export const getDownloadPublicKeyURL = async (
  params: Pick<TGeneratePublicKeyPayload, 'client_id'>
) => {
  return Api.get<AxiosResponse<{ url: string }>>(
    `/${APP_API_VERSION}/public-key`,
    { params }
  ).then(successResolver)
}
/** ********************************************************************
 * Global Settting:  Attack Protection
 */

export type ATTACK_PROTECTION = {
  id: string
  key: string
  value: string
}

export const getAttackProtection = async () => {
  const params = { group: 'attack_protection' }
  return Api.get<AxiosResponse<ATTACK_PROTECTION[]>>(
    `/${APP_API_VERSION}/global-settings`,
    { params }
  ).then(successResolver)
}

export const patchAttackProtection = async (settings: ATTACK_PROTECTION[]) => {
  return Api.patch(`/${APP_API_VERSION}/global-settings`, { settings }).then(
    successResolver
  )
}

/** ********************************************************************
 * Servie User: Attack Protection
 */

export type FAILED_LOGIN = {
  id: string
  email: string
  ip_address: string
  location: string
  failed_attempts: number
  locked_until: string
  created_at: string
  updated_at: string
}

export const getFailedLogins = async (userId: TID) => {
  return Api.get<AxiosResponse<FAILED_LOGIN[]>>(
    `/${APP_API_VERSION}/users/${userId}/failed-logins`
  ).then(successResolver)
}

export const deleteFailedLogin = async (userId: TID, failedLoginId: TID) => {
  return Api.delete(
    `/${APP_API_VERSION}/users/${userId}/failed-logins/${failedLoginId}`
  ).then(successResolver)
}

export const deleteAllFailedLogins = async (userId: TID) => {
  return Api.delete(`/${APP_API_VERSION}/users/${userId}/failed-logins`).then(
    successResolver
  )
}

/** ********************************************************************
 * Servie User: Attack Protection
 */
export type TActivityLog<T> = {
  id: number
  created_by: {
    id: string
    name: string
    email: string
  }
  action: ACTIVITY_ACTION
  auditable_id: string | null
  date: string
  additional_data: T
}

export type ActivityLogRequestParams = APIWithPager<{
  action: ACTIVITY_ACTION
  auditable_id: TID
}>

export type ActivityLogResponse<T> = APIWithPagerMeta<{
  data: TActivityLog<T>[]
}>

export const getActivityLogs = async <T>(params: ActivityLogRequestParams) => {
  return Api.get<ActivityLogResponse<T>>(`/${APP_API_VERSION}/activity-logs`, {
    params
  }).then(successResolver)
}
